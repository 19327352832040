import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { ConnectBusinessRequest } from "@/types/requests/yollloBusinessController/ConnectBusinessRequest";
import { SubscribeInfoResponse } from "@/types/responses/yollloBusinessController/SubscribeInfoResponse";

class YollloBusinessApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/business`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async connect(
    payload: ConnectBusinessRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/connect",
      data: payload,
    });
  }

  async subscribe(): Promise<[null, SubscribeInfoResponse] | [unknown]> {
    return this.axiosCall<SubscribeInfoResponse>({
      method: "post",
      url: "/subscribe",
    });
  }

  async getParentRefLink(): Promise<[null, string] | [unknown]> {
    return this.axiosCall<string>({
      method: "get",
      url: "/parent_referral_link",
    });
  }
}

export const yollloBusinessApi = new YollloBusinessApiService();
