import { defineStore } from "pinia";
import { DefaultSendTransactionResponse } from "@/types/abstract/blockchain/response/DefaultSendTransactionResponse";

export const useTransactionStore = defineStore({
  id: "transaction",
  state: () => ({
    sendTransactionResponse: null as DefaultSendTransactionResponse | null,
    ignoreStateMutation: false,
  }),
  getters: {
    getTxStatus(): string {
      return this.sendTransactionResponse?.status || "";
    },
    getTxHash(): string {
      return this.sendTransactionResponse?.hash || "";
    },
  },
  actions: {
    setSendTransactionResponse(payload: DefaultSendTransactionResponse | null) {
      this.sendTransactionResponse = payload;
    },
    setIgnoreStateMutation(payload: boolean) {
      this.ignoreStateMutation = payload;
    },
  },
});
