import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { GetAllChatsResponse } from "@/types/responses/chat/GetAllChatsResponse";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { GetOrCreateChatResponse } from "@/types/responses/chat/GetOrCreateChatResponse";
import { Chat } from "@/types/chat/Chat";
import { GetAllMessagesResponse } from "@/types/responses/chat/GetAllMessagesResponse";
import { ReadMessageResponse } from "@/types/responses/chat/ReadMessageResponse";
import { Message } from "@/types/chat/Message";
import { DefaultPageableRequest } from "@/types/abstract/pagination/request/DefaultPageableRequest";
import { SendMessageRequest } from "@/types/requests/chat/SendMessageRequest";
import { DeleteMessageRequest } from "@/types/requests/chat/DeleteMessageRequest";

class ChatApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_CHAT_URL}/chat`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getAllChats(
    payload: DefaultPageableRequest
  ): Promise<[null, GetAllChatsResponse] | [unknown]> {
    return this.axiosCall<GetAllChatsResponse>({
      method: "get",
      url: "/find-chats",
      params: payload.params,
      data: payload.data,
    });
  }

  async getOrCreateChat(payload: {
    userId: string;
  }): Promise<[null, GetOrCreateChatResponse] | [unknown]> {
    return this.axiosCall<GetOrCreateChatResponse>({
      method: "post",
      url: "/get-chat",
      data: payload,
    });
  }

  async getChatRoomById(payload: {
    chatId: string;
  }): Promise<[null, Chat] | [unknown]> {
    return this.axiosCall<Chat>({
      method: "get",
      url: "/",
      params: payload,
    });
  }

  async getAllMessagesByChatRoomId(
    payload: DefaultPageableRequest
  ): Promise<[null, GetAllMessagesResponse] | [unknown]> {
    return this.axiosCall<GetAllMessagesResponse>({
      method: "get",
      url: "/all-messages",
      params: payload.params,
      data: payload.data,
    });
  }

  async readMessage(payload: {
    messageId: string;
  }): Promise<[null, ReadMessageResponse] | [unknown]> {
    return this.axiosCall<ReadMessageResponse>({
      method: "post",
      url: "/read-message",
      data: payload,
    });
  }

  async sendMessage(
    payload: SendMessageRequest
  ): Promise<[null, Message] | [unknown]> {
    return this.axiosCall<Message>({
      method: "post",
      url: "/send-message",
      data: payload,
    });
  }

  async deleteChat(payload: {
    chatId: string;
  }): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "delete",
      url: "/",
      data: payload,
    });
  }

  async deleteMessage(
    payload: DeleteMessageRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "delete",
      url: "/delete-message",
      data: payload,
    });
  }

  async getAmountAllNotReadMessages(): Promise<[null, number] | [unknown]> {
    return this.axiosCall<number>({
      method: "get",
      url: "/not-read-messages-amount",
    });
  }
}

export const chatAPI = new ChatApiService();
