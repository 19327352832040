import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { ConfirmLoginRequest } from "@/types/requests/confirmLoginController/ConfirmLoginRequest";
import { EnableTwoFaRequest } from "@/types/requests/confirmLoginController/EnableTwoFaRequest";
import { DisableTwoFaRequest } from "@/types/requests/confirmLoginController/DisableTwoFaRequest";
import { GetSettingsResponse } from "@/types/responses/confirmLoginController/GetSettingsResponse";
import { LoginResponse } from "@/types/responses/authController/LoginResponse";

class ConfirmLoginApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/confirm_login`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async confirmLogin(
    payload: ConfirmLoginRequest
  ): Promise<[null, LoginResponse] | [unknown]> {
    return this.axiosCall<LoginResponse>({
      method: "post",
      url: "",
      data: payload,
    });
  }

  async registerTwoFa(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/register_two_fa",
    });
  }

  async enableTwoFa(
    payload: EnableTwoFaRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/enable_two_fa",
      params: payload,
    });
  }

  async disableTwoFa(
    payload: DisableTwoFaRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/disable_two_fa",
      params: payload,
    });
  }

  async getTwoFaSecret(): Promise<[null, string] | [unknown]> {
    return this.axiosCall<string>({
      method: "get",
      url: "/get_two_fa_secret",
    });
  }

  async getTwoQrLink(): Promise<[null, string] | [unknown]> {
    return this.axiosCall<string>({
      method: "get",
      url: "/get_two_fa_qr_link",
    });
  }

  async getSettings(): Promise<[null, GetSettingsResponse] | [unknown]> {
    return this.axiosCall<GetSettingsResponse>({
      method: "get",
      url: "/settings",
    });
  }

  async enableMailConfirm(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/enable_mail_confirm",
    });
  }

  async disableMailConfirm(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/disable_mail_confirm",
    });
  }
}

export const confirmLoginApi = new ConfirmLoginApiService();
