import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { confirmLoginApi } from "@/api/confirmLogin.api";

export const useSecurityStore = defineStore({
  id: "security",
  state: () => ({
    banned: false,
    mailCode: false,
    twoFa: false,
  }),
  getters: {
    getBanned(): boolean {
      return this.banned;
    },
    getMailCodeEnabled(): boolean {
      return this.mailCode;
    },
    getTwoFaEnabled(): boolean {
      return this.twoFa;
    },
  },
  actions: {
    setTwoFaStatus(payload: boolean) {
      this.twoFa = payload;
    },
    setMailCodeStatus(payload: boolean) {
      this.mailCode = payload;
    },
    async getSettings() {
      const [error, response] = await confirmLoginApi.getSettings();
      if (error) {
        await useExceptionHandler(error);
      }

      if (response) {
        this.$state = { ...response };
      }
    },
  },
});
