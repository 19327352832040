import { computed, ref } from "vue";
import { AppNotification } from "@/types/notification/AppNotification";
import { AppNotificationsStatusEnum } from "@/utils/enum/notifications/appNotificationsStatusEnum";
import { AppNotificationsTypeEnum } from "@/utils/enum/notifications/appNotificationsTypeEnum";

export const useNotificationInfo = () => {
  const notification = ref<AppNotification | null>(null);

  const setNotification = (payload: AppNotification | null) => {
    notification.value = payload;
  };

  const notificationId = computed<string>(() => notification?.value?.id || "");

  const notificationCreated = computed<number>(
    () => notification?.value?.created || 0
  );

  const notificationAmount = computed<number>(
    () => notification?.value?.amount || 0
  );

  const notificationCurrency = computed<string>(
    () => notification?.value?.currency || ""
  );

  const notificationStatus = computed<AppNotificationsStatusEnum | null>(
    () => notification?.value?.status || null
  );

  const notificationType = computed(() => notification?.value?.type || null);

  const isAttentionNotification = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.DEPOSIT_SUCCESSFUL ||
      notificationType.value ===
        AppNotificationsTypeEnum.WITHDRAWAL_REQUEST_SUCCESS ||
      notificationType.value ===
        AppNotificationsTypeEnum.WITHDRAWAL_CONFIRMATION ||
      notificationType.value ===
        AppNotificationsTypeEnum.CHANGE_PASSWORD_SUCCESSFUL ||
      notificationType.value === AppNotificationsTypeEnum.REPORT_ON_YOUR_POST ||
      notificationType.value ===
        AppNotificationsTypeEnum.POST_GET_10_YRM_MINTING_NFT_AVAILABLE ||
      notificationType.value ===
        AppNotificationsTypeEnum.CALIBRATED_THE_FEED_SUCCESSFUL ||
      notificationType.value === AppNotificationsTypeEnum.YOUR_AUCTION_CLOSED ||
      notificationType.value ===
        AppNotificationsTypeEnum.NFT_MINTED_SUCCESSFUL ||
      notificationType.value ===
        AppNotificationsTypeEnum.YOUR_BID_WIN_ON_AUCTION ||
      notificationType.value === AppNotificationsTypeEnum.NFT_AUCTION_TIME_OUT
  );

  const isNotificationContainsMedia = computed(
    () =>
      notificationType.value ===
        AppNotificationsTypeEnum.NEW_LIKE_ON_YOUR_POST ||
      notificationType.value ===
        AppNotificationsTypeEnum.NEW_YRM_ON_YOUR_POST ||
      notificationType.value === AppNotificationsTypeEnum.REPORT_ON_YOUR_POST ||
      notificationType.value ===
        AppNotificationsTypeEnum.POST_GET_10_YRM_MINTING_NFT_AVAILABLE ||
      notificationType.value ===
        AppNotificationsTypeEnum.NEW_BID_ON_YOUR_AUCTION ||
      notificationType.value === AppNotificationsTypeEnum.YOUR_AUCTION_CLOSED ||
      notificationType.value === AppNotificationsTypeEnum.SELL_NFT_SUCCESSFUL ||
      notificationType.value ===
        AppNotificationsTypeEnum.TRANSFER_NFT_SUCCESSFUL ||
      notificationType.value ===
        AppNotificationsTypeEnum.NFT_MINTED_SUCCESSFUL ||
      notificationType.value ===
        AppNotificationsTypeEnum.YOUR_BID_WIN_ON_AUCTION ||
      notificationType.value === AppNotificationsTypeEnum.NFT_AUCTION_TIME_OUT
  );

  const isNewLikeOnYourPost = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.NEW_LIKE_ON_YOUR_POST
  );

  const isNewYrmOnYourPost = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.NEW_YRM_ON_YOUR_POST
  );

  const isDepositSuccessful = computed(
    () => notificationType.value === AppNotificationsTypeEnum.DEPOSIT_SUCCESSFUL
  );

  const isWithdrawRequestSuccess = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.WITHDRAWAL_REQUEST_SUCCESS
  );

  const isWithdrawalConfirmation = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.WITHDRAWAL_CONFIRMATION
  );

  const isNewSubscriber = computed(
    () => notificationType.value === AppNotificationsTypeEnum.NEW_SUBSCRIBER
  );

  const isChangePasswordSuccessful = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.CHANGE_PASSWORD_SUCCESSFUL
  );

  const isReportOnYourPost = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.REPORT_ON_YOUR_POST
  );

  const isMintingAvailable = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.POST_GET_10_YRM_MINTING_NFT_AVAILABLE
  );

  const isCalibratedFeedSuccessful = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.CALIBRATED_THE_FEED_SUCCESSFUL
  );

  const isNewBidOnYourAuction = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.NEW_BID_ON_YOUR_AUCTION
  );

  const isYourAuctionClosed = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.YOUR_AUCTION_CLOSED
  );

  const isSellNftSuccessful = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.SELL_NFT_SUCCESSFUL
  );

  const isTransferNftSuccessful = computed(
    () =>
      notificationType.value ===
      AppNotificationsTypeEnum.TRANSFER_NFT_SUCCESSFUL
  );

  const isYourNftMintedSuccessful = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.NFT_MINTED_SUCCESSFUL
  );

  const isYourBidWinOnAuction = computed(
    () =>
      notificationType.value === AppNotificationsTypeEnum.NFT_MINTED_SUCCESSFUL
  );

  const appointNotificationIcon = computed(() => {
    let icon = "";
    if (isNewLikeOnYourPost.value) {
      icon = "new-like";
    }
    if (isNewYrmOnYourPost.value) {
      icon = "new-yrm";
    }
    if (isDepositSuccessful.value) {
      icon = "deposit-success";
    }
    if (isWithdrawRequestSuccess.value) {
      icon = "withdraw-success";
    }
    if (isWithdrawalConfirmation.value) {
      icon = "withdraw-confirm";
    }
    if (isNewSubscriber.value) {
      icon = "new-subscriber";
    }
    if (isChangePasswordSuccessful.value) {
      icon = "change-pass-success";
    }
    if (isReportOnYourPost.value) {
      icon = "report";
    }
    if (isMintingAvailable.value) {
      icon = "get-10-yrm";
    }
    if (isCalibratedFeedSuccessful.value) {
      icon = "calibrate-feed";
    }
    if (isNewBidOnYourAuction.value) {
      icon = "new-bid";
    }
    if (isYourAuctionClosed.value) {
      icon = "auction-closed";
    }
    if (isSellNftSuccessful.value) {
      icon = "sell-nft-success";
    }
    if (isTransferNftSuccessful.value) {
      icon = "transfer-success";
    }
    if (isYourNftMintedSuccessful.value) {
      icon = "nft-minted";
    }
    if (isYourBidWinOnAuction.value) {
      icon = "nft-minted";
    }
    return require(`@/assets/img/svg/notifications/${icon}.svg`);
  });

  return {
    setNotification,
    notificationId,
    notificationCreated,
    notificationStatus,
    notificationType,
    isAttentionNotification,
    isNotificationContainsMedia,
    notificationAmount,
    notificationCurrency,
    appointNotificationIcon,
  };
};
