<script setup>
import AppModal from "@/components/slots/modals/AppModal";
import AppModalImage from "@/components/slots/modals/AppModalImage";
import AppModalText from "@/components/slots/modals/AppModalText";
import { useGeneralStore } from "@/stores/general";
import { useI18n } from "vue-i18n";
import AppBtn from "@/components/slots/buttons/AppBtn";
import { useLink } from "@/composition/common/useLink";

const general = useGeneralStore();
const { t } = useI18n();
const { signInLink } = useLink();
</script>

<template>
  <app-modal
    class="success-modal email-modal"
    @close="general.setEmailInfo(null)"
  >
    <app-modal-image>
      <img src="@/assets/img/svg/info-modal-img.svg" alt="mail-icon" />
    </app-modal-image>
    <app-modal-text>
      {{ t(general.getEmailInfo) }}
    </app-modal-text>

    <app-btn
      @click="
        general.setEmailInfo(null);
        $router.push(signInLink());
      "
    >
      {{ t("modal-phrase-1") }}
    </app-btn>
  </app-modal>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/components/modals/auth/emailInfoModal";
</style>
