<script setup>
import AppModal from "@/components/slots/modals/AppModal";
import AppModalImage from "@/components/slots/modals/AppModalImage";
import { useI18n } from "vue-i18n";
import AppBtn from "@/components/slots/buttons/AppBtn";
import AppModalTitle from "@/components/slots/modals/AppModalTitle";
import { EventsEnum } from "@/utils/enum/general/eventsEnum";
import { authAPI } from "@/api/auth.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { onMounted, ref, defineEmits } from "vue";
import { useGeneralStore } from "@/stores/general";
import { SuccessesEnum } from "@/utils/enum/general/successesEnum";

const { t } = useI18n();
const confirmLink = ref("");
const loading = ref(false);
const emits = defineEmits([EventsEnum.CLOSE]);

const getRegistrationLink = async () => {
  const [error, response] = await authAPI.getRegistrationLink();
  if (error) {
    await useExceptionHandler(error);
    return;
  }
  confirmLink.value = response;
};

const confirmRegistration = async () => {
  loading.value = true;
  const [error] = await authAPI.confirmRegistration(
    confirmLink.value.split("token=")[1]
  );
  if (error) {
    await useExceptionHandler(error);
    loading.value = false;
    return;
  }
  if (!error) {
    const general = useGeneralStore();
    general.setSuccess(SuccessesEnum.ACCOUNT_ACTIVATED);
    loading.value = false;
    emits(EventsEnum.CLOSE);
  }
};

onMounted(() => {
  getRegistrationLink();
});
</script>

<template>
  <app-modal
    class="success-modal provide-modal"
    @close="emits(EventsEnum.CLOSE)"
  >
    <app-modal-image>
      <img src="@/assets/img/svg/email-img.svg" alt="data-img" />
    </app-modal-image>
    <app-modal-title class="popup__title">
      {{ t("confirm-phrase-1") }}
    </app-modal-title>
    <div class="popup__msg">
      {{ t("confirm-phrase-2") }}
    </div>

    <app-btn @click="confirmRegistration" :loading="loading">
      {{ t("confirm-phrase-3") }}
    </app-btn>
  </app-modal>
</template>
