<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33331 2.662C1.33453 2.48692 1.40458 2.31934 1.52832 2.19548C1.65206 2.07161 1.81957 2.0014 1.99465 2H14.0053C14.3706 2 14.6666 2.29667 14.6666 2.662V13.338C14.6654 13.5131 14.5954 13.6807 14.4716 13.8045C14.3479 13.9284 14.1804 13.9986 14.0053 14H1.99465C1.81919 13.9998 1.65098 13.93 1.52697 13.8059C1.40297 13.6817 1.33331 13.5135 1.33331 13.338V2.662ZM5.33331 3.33333V12.6667H10.6666V3.33333H5.33331ZM2.66665 3.33333V4.66667H3.99998V3.33333H2.66665ZM12 3.33333V4.66667H13.3333V3.33333H12ZM2.66665 6V7.33333H3.99998V6H2.66665ZM12 6V7.33333H13.3333V6H12ZM2.66665 8.66667V10H3.99998V8.66667H2.66665ZM12 8.66667V10H13.3333V8.66667H12ZM2.66665 11.3333V12.6667H3.99998V11.3333H2.66665ZM12 11.3333V12.6667H13.3333V11.3333H12Z"
      fill="#ACADB2"
    />
  </svg>
</template>
