export enum CurrencyEnum {
  YOLLLO = "YOLLLO",
  MATIC = "MATIC",
  USDT = "USDT",
  BTC = "BTC",
  ETH = "ETH",
  BNB = "BNB",
  USD = "USD",
  EUR = "EUR",
}
