import { chatAPI } from "@/api/chat.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import router from "@/router";
import { useLink } from "@/composition/common/useLink";
import { LSChat } from "@/types/chat/LSChat";
import { ref } from "vue";
import { Chat } from "@/types/chat/Chat";
import { DEFAULT_LOADING_SIZE } from "@/utils/consts/constants";
import { ReceiveMessageResponse } from "@/types/socket/chat/responses/ReceiveMessageResponse";
import { useEventsBus } from "@/composition/event/useEventBus";
import { EventsEnum } from "@/utils/enum/general/eventsEnum";
import { Message } from "@/types/chat/Message";
import { ChatUserResponse } from "@/types/user/responses/ChatUserResponse";
import { GetOrCreateChatResponse } from "@/types/responses/chat/GetOrCreateChatResponse";
import { ContentMediaTypeEnum } from "@/utils/enum/chat/message/contentMediaTypeEnum";
import { MessageStatusesEnum } from "@/utils/enum/chat/message/messageStatusesEnum";

const currentChat = ref<Chat | null>(null);
const chatInfoInitialized = ref(false);
const amountAllNotReadMessages = ref(0);
const chatPreparedToUse = ref(false);

export const useChat = () => {
  const { emit } = useEventsBus();
  const { chatRoomLink } = useLink();
  const setCurrentChat = (val: Chat | null) => {
    currentChat.value = val;
    chatInfoInitialized.value = true;
  };

  const setChatPreparedToUse = (val: boolean) => {
    chatPreparedToUse.value = val;
  };

  const increaseAmountAllNotReadMessages = () => {
    amountAllNotReadMessages.value = +1;
  };

  const getAmountAllNotReadMessages = async () => {
    const [error, response] = await chatAPI.getAmountAllNotReadMessages();

    if (error) {
      await useExceptionHandler(error);
    }

    if (response || response === 0) {
      amountAllNotReadMessages.value = response;
    }
  };

  const checkChatExistInLocalStorage = (chatId: string): LSChat | null => {
    const storeRecord = localStorage.getItem("chats");
    if (storeRecord) {
      const chats: LSChat[] = JSON.parse(storeRecord);
      return chats.find((i) => i.chatId === chatId) || null;
    } else {
      return null;
    }
  };

  const getSavedChats = (): LSChat[] => {
    const storeRecord = localStorage.getItem("chats");
    if (storeRecord) {
      return JSON.parse(storeRecord);
    } else {
      return [];
    }
  };

  const deleteChatFromLocalStorage = (chatId: string) => {
    let chats = getSavedChats();
    chats = chats.filter((i) => i.chatId !== chatId);
    localStorage.removeItem("chats");
    localStorage.setItem("chats", JSON.stringify(chats));
  };

  const setChatToLocalStorage = (
    chatId: string,
    page: number,
    lastSeenMessage: string
  ) => {
    let chats = getSavedChats();
    if (chats.length) {
      const chat = chats.find((i) => i.chatId === chatId);
      chats = chats.filter((i) => i.chatId !== chatId);
      chats.push({
        chatId,
        page,
        lastSeenMessage: lastSeenMessage
          ? lastSeenMessage
          : chat && chat.lastSeenMessage
          ? chat.lastSeenMessage
          : lastSeenMessage,
      });
      localStorage.removeItem("chats");
    } else {
      chats = [{ chatId, page, lastSeenMessage }];
    }
    localStorage.setItem("chats", JSON.stringify(chats));
  };

  const calcPageRequiredToLoad = (
    chatId: string,
    amountOfNotReadMessages: number
  ) => {
    const lsChat = checkChatExistInLocalStorage(chatId);
    let pageRequiredToLoad = Math.floor(
      amountOfNotReadMessages / DEFAULT_LOADING_SIZE
    );
    if (lsChat) {
      if (lsChat.page > pageRequiredToLoad) {
        pageRequiredToLoad = lsChat.page;
      }
    }
    return pageRequiredToLoad;
  };

  const goToChat = async (chatId: string) => {
    await router.push({
      path: chatRoomLink(chatId),
    });
  };

  const getChatById = async (chatId: string): Promise<Chat | null> => {
    const [error, response] = await chatAPI.getChatRoomById({
      chatId,
    });
    if (error) {
      await useExceptionHandler(error);
      await router.push({
        path: chatRoomLink(""),
      });
      return null;
    }
    if (response) {
      return response;
    }
    return null;
  };

  const getOrCreateChat = async (
    userId: string,
    redirectToChat = true
  ): Promise<GetOrCreateChatResponse | null> => {
    const [error, response] = await chatAPI.getOrCreateChat({ userId });
    if (error) {
      await useExceptionHandler(error);
      return null;
    }
    if (response) {
      if (redirectToChat) {
        await goToChat(response.id);
        emit(EventsEnum.GET_OR_CREATE_CHAT, { chatRoomId: response.id });
      }
      return response;
    }
    return null;
  };

  const getLastSeenMessageFromLocalStorage = () => {
    const chats = getSavedChats();
    const chat = chats.find((i) => i.chatId === currentChat.value?.chatRoomId);
    if (chat) {
      return chat.lastSeenMessage;
    } else {
      return "";
    }
  };

  const goToLastSeenMessage = () => {
    const lastSeenMessage = document.getElementById(
      getLastSeenMessageFromLocalStorage()
    );
    if (lastSeenMessage) {
      lastSeenMessage.scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "nearest",
      });
    } else {
      goToChatBottom();
    }
  };

  const goToChatBottom = () => {
    const chatArea = document.getElementById("chat-area");
    if (chatArea) {
      chatArea.scrollTo({ top: chatArea.scrollHeight, behavior: "smooth" });
    }
  };

  const getChatContainer = () => {
    return document.getElementById("chat-area");
  };

  const receiveChatNotification = async (
    socketNotification: ReceiveMessageResponse
  ) => {
    if (currentChat.value?.chatRoomId === socketNotification.chatRoomId) {
      return;
    }
    switch (socketNotification.messageStatus) {
      case MessageStatusesEnum.SENT:
        amountAllNotReadMessages.value += 1;
        emit(EventsEnum.RECEIVE_MESSAGE_NOTIFICATION, {
          chatRoomId: socketNotification.chatRoomId,
          sentAt: socketNotification.sentAt,
          text: socketNotification.text,
          userAvatar: socketNotification.userInfo.avatarUrl,
          userName: (<ChatUserResponse>socketNotification.userInfo).name,
          userSurname: (<ChatUserResponse>socketNotification.userInfo).surname,
          userNickname: socketNotification.userInfo.nickname,
          contentMediaType: socketNotification.contentMediaType,
        });
        break;
      case MessageStatusesEnum.DELETED:
        emit(EventsEnum.MESSAGE_DELETED, {
          chatRoomId: socketNotification.chatRoomId,
        });
        await getAmountAllNotReadMessages();
        break;
    }
  };

  const sendMessageToChat = async (
    chatRoomId: string,
    text: string,
    contentMediaType: ContentMediaTypeEnum,
    linkFile = "",
    previewLinkFile = "",
    postLink = "",
    postId = ""
  ): Promise<[null, Message] | [unknown]> => {
    const [error, response] = await chatAPI.sendMessage({
      chatRoomId,
      forwardId: "",
      forwardType: "MESSAGE",
      photo: [],
      contentMediaType,
      text,
      linkFile,
      previewLinkFile,
      postLink,
      postId,
    });

    if (error) {
      return [error];
    }
    return [null, <Message>response];
  };

  return {
    getOrCreateChat,
    goToChat,
    setCurrentChat,
    getChatById,
    chatInfoInitialized,
    calcPageRequiredToLoad,
    setChatToLocalStorage,
    goToLastSeenMessage,
    goToChatBottom,
    getChatContainer,
    currentChat,
    getAmountAllNotReadMessages,
    amountAllNotReadMessages,
    deleteChatFromLocalStorage,
    sendMessageToChat,
    receiveChatNotification,
    increaseAmountAllNotReadMessages,
    setChatPreparedToUse,
    chatPreparedToUse,
  };
};
