<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 1.3335L14 4.66683V13.9955C14 14.3662 13.7033 14.6668 13.338 14.6668H2.662C2.29667 14.6668 2 14.3635 2 14.0055V1.99483C2 1.6295 2.298 1.3335 2.666 1.3335H10.6667ZM10 2.66683H3.33333V13.3335H12.6667V5.3335H10V2.66683Z"
      fill="#ACADB2"
    />
    <rect x="8" y="6.38086" width="1.14286" height="4.57143" fill="#ACADB2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.71429 6.38086H10.8572V6.38087H12.0001V7.52373H10.8572V8.66659H12.0001V9.80944H10.8572V10.9523H9.71429V6.38086Z"
      fill="#ACADB2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 6.38086H5.14286V6.38087H7.42857V7.52373H5.14286V9.80944H6.28571V9.23801H5.71429V8.09516H7.42857L7.42857 8.66659V9.80944V10.9523H6.28571H5.14286V10.9523H4V6.38086Z"
      fill="#ACADB2"
    />
  </svg>
</template>
