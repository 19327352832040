<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="url(#paint0_linear_2_7)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 34.9933C15.0039 23.969 23.9762 15.0001 35.0089 15.0001C37.6366 14.9938 40.2393 15.5089 42.6665 16.5154C45.0937 17.5218 47.2972 18.9998 49.1494 20.8636C51.0119 22.7173 52.4882 24.922 53.4928 27.3501C54.4975 29.7783 55.0105 32.3816 55.0021 35.0093C54.9979 46.0342 46.0256 55.0041 35.0014 55.0041H34.9939C31.4041 55.0022 27.8839 54.0387 24.8138 52.2166L24.0828 51.7836L16.5127 53.7685L18.5336 46.3907L18.0577 45.6342C16.0547 42.4469 14.9946 38.7577 15 34.9933ZM27.6338 23.8961C28.062 23.9134 28.5362 23.9337 28.9869 24.9349V24.9352C29.2938 25.6182 29.8099 26.8898 30.222 27.9056C30.5275 28.6584 30.776 29.2706 30.84 29.3986C30.9905 29.6991 31.0902 30.0511 30.8901 30.4517C30.8593 30.5133 30.8309 30.5713 30.8038 30.6268C30.6547 30.9318 30.5445 31.1572 30.2896 31.4542C30.1877 31.5728 30.0824 31.7008 29.9772 31.8287C29.7713 32.079 29.5658 32.3289 29.3872 32.5073C29.0857 32.8069 28.7717 33.1326 29.123 33.7341C29.4742 34.3356 30.6807 36.3038 32.4681 37.8976C34.3919 39.613 36.0625 40.3372 36.9083 40.7039C37.0725 40.7751 37.2056 40.8328 37.3034 40.8817C37.9052 41.1822 38.2555 41.1319 38.6064 40.7306C38.9573 40.3293 40.1083 38.9756 40.5096 38.3738C40.9109 37.772 41.3115 37.8717 41.8626 38.0726C42.4137 38.2734 45.3694 39.7278 45.9712 40.0287C46.0895 40.0879 46.2001 40.1412 46.3026 40.1906C46.721 40.3923 47.0034 40.5285 47.124 40.7306C47.2742 40.9821 47.2738 42.185 46.7731 43.5891C46.2724 44.9932 43.8178 46.3475 42.7149 46.4476C42.6088 46.4572 42.5032 46.4696 42.3947 46.4824C41.3756 46.6021 40.0896 46.7532 35.4996 44.9431C29.8461 42.7137 26.12 37.1865 25.3553 36.0523C25.2933 35.9604 25.2508 35.8973 25.2283 35.8672L25.2212 35.8576C24.8956 35.4223 22.7734 32.5847 22.7734 29.6488C22.7734 26.8812 24.1334 25.4298 24.7597 24.7614C24.8029 24.7154 24.8425 24.6731 24.878 24.6343C25.4288 24.0325 26.0803 23.882 26.4813 23.882L27.6338 23.8961Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_7"
        x1="34.2874"
        y1="4.20076"
        x2="34.6422"
        y2="64.0201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57D163" />
        <stop offset="1" stop-color="#2DC23B" />
      </linearGradient>
    </defs>
  </svg>
</template>
