import { createApp } from "vue";
import App from "./App.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VueClipboard from "vue3-clipboard";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VeProgress from "vue-ellipse-progress";
import Vue3TouchEvents from "vue3-touch-events";
import VueLazyLoad from "vue3-lazyload";
import { i18n } from "@/i18n/i18n";
import mitt from "mitt";
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { vue3Debounce } from "vue-debounce";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import router from "./router";
import VueFullscreen from "vue-fullscreen";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VOtpInput from "vue3-otp-input";
import VueSocialSharing from "vue-social-sharing";
const app = createApp(App);
import { createPinia } from "pinia";

const emitter = mitt();

const options: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 5000,
  pauseOnHover: false,
  newestOnTop: true,
  maxToasts: 5,
};

app.config.globalProperties.emitter = emitter;
app.component(Skeletor.name, Skeletor);
app.component("AppDateTimePicker", Datepicker);
app.directive("debounce", vue3Debounce({ lock: true }));
app.component(VueCountdown.name, VueCountdown);
app.component("v-otp-input", VOtpInput);

app
  .use(createPinia())
  .use(VueSocialSharing)
  .use(Vue3TouchEvents)
  .use(VueLazyLoad)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(router)
  .use(i18n)
  .use(Toast, options)
  .use(VeProgress)
  .use(VueFullscreen)
  .mount("#app");
