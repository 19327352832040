<template>
  <div class="loader-btn">
    <div class="loader-btn__icon">
      <Vue3Lottie :animationData="PreloaderBtnJSON" :height="50" :width="50" />
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import PreloaderBtnJSON from "@/plugins/lottie/preloaderBtn.json";
export default {
  name: "ButtonPreloader",
  data() {
    return {
      PreloaderBtnJSON,
    };
  },
  components: {
    Vue3Lottie,
  },
};
</script>

<style lang="scss">
.loader-btn {
  &__icon {
    margin-top: -4px;
  }
}
</style>
