// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { User } from "@/types/user/User";
import { PublicLargeUserResponse } from "@/types/user/responses/PublicLargeUserResponse";
import { LargeUserResponse } from "@/types/user/responses/LargeUserResponse";
import { ShortUserResponse } from "@/types/user/responses/ShortUserResponse";
import { IdentityStatusesEnum } from "@/utils/enum/identity/identityStatusesEnum";
import { useTextUtils } from "@/composition/utils/useTextUtils";

const { cutHash } = useTextUtils();

export const useUserInfo = () => {
  const user = ref<User | null>(null);
  const userStore = useUserStore();

  const setUser = (payload: User | null) => {
    user.value = payload;
  };

  const isIdentifiedUser = computed(
    () => user.value?.priorityStatus === IdentityStatusesEnum.PRIORITY
  );

  const isConfirmedAgreement = computed(() => user.value?.isConfirmedAgreement);

  const userLastVisitTime = computed<number>({
    get() {
      return user.value?.lastVisitAt || 0;
    },
    set(val) {
      user.value.lastVisitAt = val;
    },
  });

  const increaseNumberOfFollowings = () => {
    (
      user.value as PublicLargeUserResponse | LargeUserResponse
    ).numberOfFollowing += 1;
  };

  const reduceNumberOfFollowings = () => {
    (
      user.value as PublicLargeUserResponse | LargeUserResponse
    ).numberOfFollowing -= 1;
  };

  const setIsFollow = (val: boolean) => {
    if (user.value) {
      if (val) {
        if (
          (user.value as PublicLargeUserResponse | LargeUserResponse)
            .numberOfFollowers != undefined
        ) {
          (user.value as PublicLargeUserResponse | LargeUserResponse)
            .numberOfFollowers++;
        }

        (user.value as PublicLargeUserResponse | LargeUserResponse).following =
          val;
      }
      if (!val) {
        if (
          (user.value as PublicLargeUserResponse | LargeUserResponse)
            .numberOfFollowers != undefined
        ) {
          (user.value as PublicLargeUserResponse | LargeUserResponse)
            .numberOfFollowers--;
        }

        (user.value as PublicLargeUserResponse | LargeUserResponse).following =
          val;
      }
    }
  };

  const isIdenticalUserToCurrent = computed<boolean>(
    () => !!userStore.userId && userId.value === userStore.getUserId
  );
  const userNickname = computed(() => user.value?.nickname);
  const userAvatar = computed(() => user.value?.avatarUrl);
  const userName = computed(() => `${user.value?.name || ""}`);
  const userSurname = computed(() => `${user.value?.surname || ""}`);
  const userIsOnline = computed(() => user.value?.isOnline || false);
  const userFullName = computed(() => {
    return (
      `${userName.value || ""} ${userSurname.value || ""}`.trim() ||
      userNickname.value ||
      cutHash(userWalletAddress.value)
    );
  });
  const userInitials = computed(
    () =>
      `${userName.value?.split("")[0] || ""}${
        userSurname.value?.split("")[0] || ""
      }` ||
      userNickname.value?.substring(0, 1) ||
      ""
  );
  const userNumberOfFollowers = computed(() => user.value?.numberOfFollowers);
  const userNumberOfFollowing = computed(() => user.value?.numberOfFollowing);
  const userNumberOfPublications = computed(
    () => user.value?.numberOfPublications
  );
  const userNumberOfNfts = computed(() => user.value?.numberOfNftTokens);

  const userBio = computed(() => user.value?.description);
  const userWalletAddress = computed(() => user.value?.walletAddress);

  const isFollow = computed<boolean>(() => user.value?.following);

  const userId = computed(() => user.value?.id || user.value?.userId);

  const reduceSubscribersIfIdenticalToCurrent = () => {
    if (isIdenticalUserToCurrent.value) {
      if (user.value && user.value.numberOfFollowers != undefined) {
        user.value.numberOfFollowers--;
      }
    }
  };

  const setUserIsOnline = (val: boolean) => {
    user.value.isOnline = val;
  };

  const nftOwnerAuctionInfo = computed(
    () => (user.value as ShortUserResponse)?.auctions || []
  );

  const onAuctionCopiesCount = computed<number>(
    () => (user.value as ShortUserResponse)?.onAuctionCopiesCount || 0
  );

  const availableCopiesCount = computed<number>(
    () => (user.value as ShortUserResponse)?.availableCopiesCount || 0
  );

  const fullCopiesCount = computed<number>(
    () => availableCopiesCount.value + onAuctionCopiesCount.value
  );

  return {
    setUser,
    userNickname,
    isIdenticalUserToCurrent,
    userAvatar,
    userInitials,
    userFullName,
    userNumberOfFollowers,
    userNumberOfFollowing,
    userNumberOfPublications,
    userBio,
    isFollow,
    userId,
    setIsFollow,
    reduceSubscribersIfIdenticalToCurrent,
    userIsOnline,
    setUserIsOnline,
    nftOwnerAuctionInfo,
    userNumberOfNfts,
    isIdentifiedUser,
    userLastVisitTime,
    userWalletAddress,
    increaseNumberOfFollowings,
    reduceNumberOfFollowings,
    onAuctionCopiesCount,
    availableCopiesCount,
    fullCopiesCount,
    isConfirmedAgreement,
  };
};
