import { _RouteRecordBase, RouteLocationNormalized } from "vue-router";
import { NextDefault } from "@/types/router/NextDefault";
import { useLink } from "@/composition/common/useLink";
import { useAuthStore } from "@/stores/auth";

const beforeEachGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (nav?: string | NextDefault) => void
) => {
  if (
    to.matched.some(
      (record: _RouteRecordBase): unknown => record.meta?.requiresAuth
    )
  ) {
    const { signInLink } = useLink();
    const auth = useAuthStore();
    if (!auth.getIsAuthorized) {
      next({
        path: signInLink(),
      });
    } else {
      next();
    }
  } else {
    next();
  }
  return next();
};

export default beforeEachGuard;
