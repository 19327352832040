import { defineStore } from "pinia";
import { LanguageEnum } from "@/utils/enum/general/languageEnum";
import router from "@/router";
import { languages } from "@/utils/consts/languages";
import { i18n } from "@/i18n/i18n";

export const useLanguageStore = defineStore({
  id: "language",
  state: () => ({
    language: null as LanguageEnum | null,
  }),
  getters: {
    getLanguage(): string | null {
      return this.language;
    },
  },
  actions: {
    setLanguage(payload: LanguageEnum) {
      this.language = payload;
      localStorage.setItem("lang", payload);
    },
    trimLangFromUrl() {
      let route = router.currentRoute.value.path;
      for (const item of languages) {
        route = route.replace(`/${item}`, "");
      }
      return route;
    },
    reinstallI18n(locale: string) {
      i18n.global.locale.value = locale || LanguageEnum.EN;
      i18n.global.fallbackLocale.value = locale || LanguageEnum.EN;
    },
    async replaceLanguage() {
      const trimmedUrl = this.trimLangFromUrl();
      const locale = localStorage.getItem("lang") || LanguageEnum.EN;
      await router.push({
        path: "/" + locale + trimmedUrl,
      });
      this.reinstallI18n(locale);
    },
  },
});
