import { computed, ref } from "vue";
import { NFT } from "@/types/nft/NFT";
import { CONVERT_TO_NFT_THRESHOLD } from "@/utils/consts/constants";
import { ContractTypeEnum } from "@/utils/enum/blockchain/ContractTypeEnum";
import { useUserStore } from "@/stores/user";
import { Auction } from "@/types/auction/Auction";
import { ContentRatingMarksMultiplierEnum } from "@/utils/enum/content/contentRatingMarksMultiplierEnum";
import { Content } from "@/types/content/Content";
import { RatingMarkTx } from "@/types/transactions/ratingMarks/RatingMarkTx";
import { useRatingMark } from "@/composition/ratingMark/useRatingMark";
import { ShortUserResponse } from "@/types/user/responses/ShortUserResponse";
import { ContentVisibleEnum } from "@/utils/enum/content/contentVisibleEnum";
import { ContentTypesEnum } from "@/utils/enum/content/contentTypesEnum";
import { AdvertisingStatusEnum } from "@/utils/enum/promotion/advertisingStatusEnum";

export const useContentInfo = () => {
  const { convertMultiplierToNumber } = useRatingMark();
  const user = useUserStore();
  const content = ref<Content | null>(null);

  const setContent = (payload: Content | null) => {
    content.value = payload;
  };

  const isNFT = (val: unknown): val is NFT => {
    return !!(<NFT>val)?.nftToken;
  };

  const getContentAuctionById = (auctionId: number) => {
    switch (contentContractType.value) {
      case ContractTypeEnum.ERC_721:
        return (content.value as NFT)?.nftToken?.activeAuction || null;
      case ContractTypeEnum.ERC_1155:
        return (
          (content.value as NFT)?.nftToken?.activeAuctions?.find(
            (i) => i.auctionId === auctionId
          ) || null
        );
      default:
        return null;
    }
  };

  const isContentEqualsToNFT = computed(() => !!(<NFT>content.value)?.nftToken);
  const contentId = computed(() => content.value?.id);
  const contentWebId = computed(() => content.value?.webId);
  const contentFileUrl = computed<string>(() => {
    if (contentType.value !== ContentTypesEnum.IMAGE) {
      return content.value?.fileUrl || "";
    }
    const chunks = content.value?.fileUrl.split(
      "https://res.cloudinary.com/yolllo-com/image/upload/"
    );
    if (chunks?.length === 2) {
      return `https://res.cloudinary.com/yolllo-com/image/upload/q_50/${chunks[1]}`;
    }
    return "";
  });
  const fullQualityContentFileUrl = computed<string>(
    () => content.value?.fileUrl || ""
  );
  const contentPreviewUrl = computed(() => content.value?.previewUrl);
  const contentType = computed(() => content.value?.type);
  const contentTitle = computed(() => content.value?.title);
  const contentCreated = computed(() => content.value?.created);
  const contentDescription = computed<string>(
    () => content.value?.description || ""
  );
  const contentTags = computed<string[]>(() => content.value?.tags || []);
  const contentCanBeConvertedToNFT = computed(() => {
    return (
      !isNFT(content.value) &&
      (contentRatingMarks?.value || 0) >= CONVERT_TO_NFT_THRESHOLD
    );
  });
  const contentCannotBeConvertedToNFT = computed(() => {
    return (
      !isNFT(content.value) &&
      (contentRatingMarks?.value || 0) < CONVERT_TO_NFT_THRESHOLD
    );
  });
  const contentComments = computed<number>({
    get() {
      return content.value?.comments || 0;
    },
    set(val) {
      if (content.value) {
        content.value.comments = val;
      }
    },
  });
  const isContentLiked = computed<boolean>({
    get() {
      return content.value?.liked || false;
    },
    set(val) {
      isContentLiked.value = val;
    },
  });
  const contentLikes = computed<number>({
    get() {
      return content.value?.likes || 0;
    },
    set(val) {
      contentLikes.value = val;
    },
  });
  const contentRatingMarks = computed(() => content.value?.ratingMarks);
  const contentSettingRatingMarks =
    computed<ContentRatingMarksMultiplierEnum | null>(
      () => content.value?.transaction?.ratingMarksValue || null
    );
  const contentRatingMarksTxId = computed<string>(
    () => content.value?.transaction?.id || ""
  );

  const contentContractAddress = computed(
    () => (content.value as NFT)?.nftToken?.contractAddress
  );
  const contentRoyalty = computed(
    () => (content.value as NFT)?.nftToken.royalty
  );
  const contentContractType = computed<ContractTypeEnum>(
    () => (content.value as NFT)?.nftToken?.contractType
  );
  const contentBlockchainId = computed(
    () => (content.value as NFT)?.nftToken?.blockChainId
  );

  const isContentErc1155 = computed<boolean>(
    () =>
      (content.value as NFT)?.nftToken?.contractType ===
      ContractTypeEnum.ERC_1155
  );

  const isContentForSale = computed<boolean>(
    () =>
      !!(content.value as NFT)?.nftToken?.activeAuction ||
      !!(content.value as NFT)?.nftToken?.activeAuctions?.length
  );

  const contentNumberOfCopies = computed<number>(
    () => (content.value as NFT)?.nftToken?.numberOfCopies || 0
  );

  const isContentOwner = computed<boolean>(() => {
    if (!isNFT(content.value)) {
      return content.value?.creator.id === user.userId;
    }
    if (contentContractType.value === ContractTypeEnum.ERC_721) {
      return (content.value as NFT)?.nftToken?.owner.userId === user.userId;
    } else {
      return (
        (content.value as NFT)?.nftToken?.owners?.findIndex(
          (i) => i.userId === user.userId
        ) !== -1
      );
    }
  });

  const nftTokenId = computed<string>(() => {
    return (content.value as NFT)?.nftToken?.id || "";
  });

  const contentAuctionInfo = computed<Auction | Auction[] | null>(() => {
    switch (contentContractType.value) {
      case ContractTypeEnum.ERC_721:
        return (content.value as NFT)?.nftToken?.activeAuction || null;
      case ContractTypeEnum.ERC_1155:
        return (content.value as NFT)?.nftToken?.activeAuctions || [];
      default:
        return null;
    }
  });

  const contentOwner = computed(() => {
    switch (contentContractType.value) {
      case ContractTypeEnum.ERC_721:
        return (content.value as NFT)?.nftToken?.owner || null;
      case ContractTypeEnum.ERC_1155:
        return (
          (content.value as NFT)?.nftToken?.owners as [ShortUserResponse]
        )[0];
      default:
        return null;
    }
  });

  const contentOwners = computed(() => {
    switch (contentContractType.value) {
      case ContractTypeEnum.ERC_721:
        return [(content.value as NFT)?.nftToken?.owner];
      case ContractTypeEnum.ERC_1155:
        return (content.value as NFT)?.nftToken?.owners as [ShortUserResponse];
      default:
        return [];
    }
  });

  const contentCreator = computed(() => {
    if (isNFT(content.value)) {
      return (content.value as NFT)?.nftToken?.creator || null;
    } else {
      return content.value?.creator || null;
    }
  });

  const contentOwnersAmount = computed(() => {
    if (contentContractType.value === ContractTypeEnum.ERC_1155) {
      return (content.value as NFT)?.nftToken?.owners?.length || 0;
    }
    return 0;
  });

  const toggleLike = () => {
    if (isContentLiked.value) {
      if (content.value) {
        content.value.liked = false;
        content.value.likes--;
      }
    } else {
      if (content.value) {
        content.value.liked = true;
        content.value.likes++;
      }
    }
  };

  const setRatingMarks = (val: RatingMarkTx) => {
    if (content.value) {
      content.value.transaction = val;
      content.value.ratingMarks += convertMultiplierToNumber(
        val?.ratingMarksValue || ContentRatingMarksMultiplierEnum.ONE
      );
    }
  };

  const unsetRatingMarks = (val: ContentRatingMarksMultiplierEnum) => {
    if (content.value) {
      content.value.transaction = undefined;
      content.value.ratingMarks -= convertMultiplierToNumber(val);
    }
  };

  const contentVisible = computed(
    () => content.value?.visibleStatus as ContentVisibleEnum
  );

  const isCurrentUserNftCreator = computed<boolean>(
    () => (content.value as NFT)?.nftToken.creator.userId === user.userId
  );

  const isRatingMarkTxSuccess = computed<boolean>(
    () => (content.value as NFT)?.availableRatingMarks >= 10
  );

  const isAdvertised = computed<boolean>(
    () => content.value?.advertisingStatus === AdvertisingStatusEnum.ACTIVE
  );

  const isVideoContent = computed<boolean>(
    () => content.value?.type === ContentTypesEnum.VIDEO
  );

  return {
    setContent,
    isNFT,
    contentFileUrl,
    contentType,
    contentPreviewUrl,
    contentTitle,
    isContentLiked,
    contentLikes,
    contentRatingMarks,
    contentId,
    contentDescription,
    toggleLike,
    contentCanBeConvertedToNFT,
    contentCannotBeConvertedToNFT,
    contentComments,
    contentSettingRatingMarks,
    contentContractAddress,
    contentRoyalty,
    contentContractType,
    isContentEqualsToNFT,
    contentBlockchainId,
    isContentErc1155,
    contentWebId,
    isContentForSale,
    contentNumberOfCopies,
    isContentOwner,
    contentAuctionInfo,
    nftTokenId,
    contentOwner,
    contentCreator,
    contentOwnersAmount,
    contentRatingMarksTxId,
    setRatingMarks,
    unsetRatingMarks,
    contentTags,
    contentOwners,
    getContentAuctionById,
    contentCreated,
    contentVisible,
    isCurrentUserNftCreator,
    isRatingMarkTxSuccess,
    fullQualityContentFileUrl,
    isAdvertised,
    isVideoContent,
  };
};
