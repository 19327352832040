import { useAuthStore } from "@/stores/auth";
import { RouteLocationNormalized } from "vue-router";
import { NextDefault } from "@/types/router/NextDefault";

export const AutoAuthGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (nav?: NextDefault | string) => void
) => {
  if (to.query.auth) {
    const auth = useAuthStore();
    await auth.logOut(false);
    auth.setIsAuthorized(to.query.auth?.toString() || "");
    next();
  } else {
    next();
  }
};
