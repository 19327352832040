import { useAuthStore } from "@/stores/auth";
import { authAPI } from "@/api/auth.api";
import { LocationQueryValue, RouteLocationNormalized } from "vue-router";
import { NextDefault } from "@/types/router/NextDefault";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { useGeneralStore } from "@/stores/general";
import { SuccessesEnum } from "@/utils/enum/general/successesEnum";
import { RoutesPathEnum } from "@/utils/enum/navigation/routesPathEnum";

const AuthGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (nav?: NextDefault | string) => void
) => {
  const auth = useAuthStore();
  if (auth.getIsAuthorized) {
    next({
      path: RoutesPathEnum.APP_ROOT_PATH,
    });
  } else {
    if (to.query.token) {
      const token: string | LocationQueryValue[] = to.query.token;
      const [error] = await authAPI.confirmRegistration(token);
      if (error) {
        await useExceptionHandler(error);
        next();
      }
      if (!error) {
        const general = useGeneralStore();
        general.setSuccess(SuccessesEnum.ACCOUNT_ACTIVATED);
        next();
      }
    } else {
      next();
    }
  }
};

export default AuthGuard;
