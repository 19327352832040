import { AxiosError } from "axios";
import { useGeneralStore } from "@/stores/general";
import { useAuthStore } from "@/stores/auth";
import { ErrorsEnum } from "@/utils/enum/general/errorsEnum";

export const useExceptionHandler = async (
  exception: unknown,
  ignoredPushToStateCode = "",
  showNeedLoginMessage = false
): Promise<string> => {
  const general = useGeneralStore();
  if (exception instanceof AxiosError) {
    const status = exception.response?.status;
    if (status === 400) {
      const code: string = exception.response?.data?.code?.toString();
      if (code !== ignoredPushToStateCode) {
        general.setError(code);
      }
      return code;
    }
    if (status === 403 || status === 401) {
      if (showNeedLoginMessage) {
        general.setError(ErrorsEnum.NEED_LOGIN);
      }
      const auth = useAuthStore();
      await auth.logOut();
      return "";
    }
    if (status === 500 || status === 405) {
      general.setError(ErrorsEnum.INTERNAL_SERVER_ERROR);
      return ErrorsEnum.INTERNAL_SERVER_ERROR;
    }
    if (status === 413) {
      general.setError(ErrorsEnum.MAX_CLOUDFLARE_FILE_SIZE_EXCEEDED);
      return ErrorsEnum.MAX_CLOUDFLARE_FILE_SIZE_EXCEEDED;
    }
    if (status === 0) {
      if (exception.config.baseURL?.includes("content_advertising")) {
        general.setError(ErrorsEnum.NEED_DISABLE_ADBLOCK);
        return ErrorsEnum.UNKNOWN_ERROR_OCCURRED;
      }
      general.setError(ErrorsEnum.UNKNOWN_ERROR_OCCURRED);
      return ErrorsEnum.UNKNOWN_ERROR_OCCURRED;
    }
  }
  return "";
};
