const DEFAULT_LOADING_SIZE = 20;
const RECOMMENDED_ACCOUNTS_LOAD = 4;
const POPULAR_TAGS_LOAD = 9;
const CONVERT_TO_NFT_THRESHOLD = 10;
const MIN_BID_ALLOWED = 0.00000001;
const MIN_BID_TIME_AUCTION_STEP_PERCENT_THRESHOLD = 10;
const MIN_NUMBER_OF_COPIES_ALLOWED = 1;
const MIN_COPIES_TO_TRANSFER_ALLOWED = 1;
const MIN_WITHDRAW_ALLOWED = 0.00000001;
const MIN_DISCOUNT_ALLOWED = 1;
const YOLLLO_TOKEN_SYMBOL = "YRM";
const MUSIC_WITHOUT_PREVIEW_DEFAULT_IMAGE_URL =
  "https://res.cloudinary.com/dm5d1rv9j/image/upload/v1659348374/Utils/card-default_unp2u4.svg";
const NEED_TWO_FA_CONFIRM_ERROR_CODE = 1004;
const AUCTION_NOT_FOUND_ERROR_CODE = 12004;
const AVATAR_MAXIMUM_UPLOAD_SIZE = 1; // value is MB
const VERIFICATION_DOCUMENT_MAXIMUM_UPLOAD_SIZE = 5; // value is MB
const MIN_ROYALTY_ALLOWED = 1;
const MAX_ROYALTY_ALLOWED = 30;
const POLYGON_SCAN_EXPLORER_URL = "https://polygonscan.com";
const MIN_PROMOTION_BUDGET_ALLOWED = 0.01;

export {
  DEFAULT_LOADING_SIZE,
  RECOMMENDED_ACCOUNTS_LOAD,
  POPULAR_TAGS_LOAD,
  CONVERT_TO_NFT_THRESHOLD,
  YOLLLO_TOKEN_SYMBOL,
  MUSIC_WITHOUT_PREVIEW_DEFAULT_IMAGE_URL,
  MIN_BID_ALLOWED,
  MIN_NUMBER_OF_COPIES_ALLOWED,
  MIN_DISCOUNT_ALLOWED,
  NEED_TWO_FA_CONFIRM_ERROR_CODE,
  AVATAR_MAXIMUM_UPLOAD_SIZE,
  MIN_ROYALTY_ALLOWED,
  AUCTION_NOT_FOUND_ERROR_CODE,
  VERIFICATION_DOCUMENT_MAXIMUM_UPLOAD_SIZE,
  MIN_BID_TIME_AUCTION_STEP_PERCENT_THRESHOLD,
  POLYGON_SCAN_EXPLORER_URL,
  MIN_WITHDRAW_ALLOWED,
  MIN_COPIES_TO_TRANSFER_ALLOWED,
  MAX_ROYALTY_ALLOWED,
  MIN_PROMOTION_BUDGET_ALLOWED,
};
