import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { VerificationStatusEnum } from "@/utils/enum/verification/verificationStatusEnum";
import { kycApi } from "@/api/kyc.api";

export const useKycStore = defineStore({
  id: "kyc",
  state: () => ({
    status: null as VerificationStatusEnum | null,
    firstName: "",
    middleName: "",
  }),
  getters: {
    isVerificationCreated(): boolean {
      return this.status === VerificationStatusEnum.CREATED;
    },
    isVerificationPending(): boolean {
      return this.status === VerificationStatusEnum.PENDING;
    },
    isVerificationSuccess(): boolean {
      return this.status === VerificationStatusEnum.SUCCESS;
    },
    isVerificationDeclined(): boolean {
      return this.status === VerificationStatusEnum.DECLINED;
    },
    getFirstName(): string {
      return this.firstName;
    },
    getMiddleName(): string {
      return this.middleName;
    },
  },
  actions: {
    setVerificationStatus(payload: VerificationStatusEnum) {
      this.status = payload;
    },
    async getKyc() {
      const [error, response] = await kycApi.getKyc();
      if (error) {
        await useExceptionHandler(error);
      }

      if (response) {
        this.status = response.status;
        this.firstName = response.firstName;
        this.middleName = response.middleName;
      }
    },
  },
});
