import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { LocationQueryValue } from "vue-router";
import { LoginResponse } from "@/types/responses/authController/LoginResponse";

class AuthAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/auth`,
    });
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async confirmRegistration(
    token: string | LocationQueryValue[]
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "put",
      url: "/registration/confirm",
      params: { token },
    });
  }

  async register(payload: {
    email: string;
    nickname: string;
    password: string;
    birthDate: string | null;
    country: string | null;
    city: string | null;
    referralUserNickname: string | null;
  }): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/registration",
      data: payload,
    });
  }

  async login(payload: {
    email: string | LocationQueryValue[];
    password: string;
  }): Promise<[null, LoginResponse] | [unknown]> {
    return this.axiosCall<LoginResponse>({
      method: "post",
      url: "/login",
      data: payload,
    });
  }

  async createChangePasswordRequest(payload: {
    email: string;
  }): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/restore_password",
      params: payload,
    });
  }

  async processChangePasswordRequest(payload: {
    password: string;
    token: string;
  }): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "put",
      url: "/restore_password/confirm",
      params: payload,
    });
  }

  async getRegistrationLink(): Promise<[null, string] | [unknown]> {
    return this.axiosCall<string>({
      method: "get",
      url: "/registration/confirm",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
  }
}

export const authAPI = new AuthAPIService();
