import axios, { AxiosRequestConfig } from "axios";
import { useUserStore } from "@/stores/user";
import router from "@/router";

export const appRequestSuccessInterceptor = async (
  config: AxiosRequestConfig
) => {
  const token = localStorage.getItem("accessToken");
  if (!token && router.currentRoute.value.meta.requiresAuth) {
    await router.push("/sign-in");
    throw new axios.Cancel("USER_CANCEL");
  }
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const appRequestErrorInterceptor = async () => {
  const user = useUserStore();
  user.unsetUser();
  if (router.currentRoute.value.meta.requiresAuth) {
    await router.push("/sign-in");
  }
};
