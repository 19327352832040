import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { ChatSocketService } from "@/sockets/chat.socket";
import { UserSocketService } from "@/sockets/user.socket";
import router from "@/router";
import { useLink } from "@/composition/common/useLink";
import { useNavigationStore } from "@/stores/navigation";
import { useWalletsStore } from "@/stores/wallets";
import { useBusinessStore } from "@/stores/business";
import { useGeneralStore } from "@/stores/general";
import { useIdentityStore } from "@/stores/identity";
import { useKycStore } from "@/stores/kyc";
import { useNotificationsStore } from "@/stores/notifications";
import { useRatingMarkStore } from "@/stores/ratingMark";
import { useReferralStore } from "@/stores/referral";
import { useSecurityStore } from "@/stores/security";
import { useSubscriptionStore } from "@/stores/subscription";
import { useTransactionStore } from "@/stores/transaction";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    isAuthorized: !!window.localStorage.getItem("accessToken") as boolean,
    accessToken: localStorage.getItem("accessToken"),
  }),
  getters: {
    getIsAuthorized: (state): boolean => state.isAuthorized,
    getAccessToken: (state): string => `Bearer ${state.accessToken || ""}`,
  },
  actions: {
    setIsAuthorized(token: string) {
      localStorage.setItem("accessToken", token);
      this.accessToken = token;
      this.isAuthorized = true;
    },
    async logOut(needRedirect = true) {
      localStorage.removeItem("accessToken");
      this.isAuthorized = false;
      const user = useUserStore();
      const wallet = useWalletsStore();
      const business = useBusinessStore();
      const general = useGeneralStore();
      const identity = useIdentityStore();
      const kyc = useKycStore();
      const navigation = useNavigationStore();
      const notification = useNotificationsStore();
      const ratingMark = useRatingMarkStore();
      const referral = useReferralStore();
      const security = useSecurityStore();
      const subscription = useSubscriptionStore();
      const transaction = useTransactionStore();
      user.unsetUser();
      business.$reset();
      general.$reset();
      identity.$reset();
      kyc.$reset();
      navigation.$reset();
      notification.$reset();
      ratingMark.$reset();
      referral.$reset();
      security.$reset();
      subscription.$reset();
      transaction.$reset();
      wallet.$reset();
      ChatSocketService.disconnect();
      UserSocketService.disconnect();
      if (router.currentRoute.value.meta.requiresAuth && needRedirect) {
        const { signInLink } = useLink();
        await router.push(signInLink());
      }
      // const navigation = useNavigationStore(); // todo check maybe can cause recursion
      // navigation.updateRootStackRouterView();
    },
  },
});
