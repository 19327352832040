import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { UpdateUserInfoRequest } from "@/types/requests/userInfoController/UpdateUserInfoRequest";
import { UploadAvatarRequest } from "@/types/requests/userInfoController/UploadAvatarRequest";
import { User } from "@/types/user/User";

class UserInfoApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/user/info`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getUserInfo(): Promise<[null, User] | [unknown]> {
    return this.axiosCall<User>({
      method: "get",
      url: "/",
    });
  }

  async updateUserInfo(
    payload: UpdateUserInfoRequest
  ): Promise<[null, User] | [unknown]> {
    return this.axiosCall<User>({
      method: "put",
      url: "/",
      data: payload,
    });
  }

  async uploadAvatar(
    payload: UploadAvatarRequest
  ): Promise<[null, User] | [unknown]> {
    return this.axiosCall<User>({
      method: "put",
      url: "/avatar",
      data: payload.formData,
    });
  }

  async confirmAgreement(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "put",
      url: "/confirm_agreement",
    });
  }
}

export const userInfoAPI = new UserInfoApiService();
