<script setup>
import AppModal from "@/components/slots/modals/AppModal";
import AppModalImage from "@/components/slots/modals/AppModalImage";
import { useI18n } from "vue-i18n";
import AppBtn from "@/components/slots/buttons/AppBtn";
import { useLink } from "@/composition/common/useLink";
import AppModalTitle from "@/components/slots/modals/AppModalTitle";
import { EventsEnum } from "@/utils/enum/general/eventsEnum";

const { t } = useI18n();
const { userEditAccountLink } = useLink();
</script>

<template>
  <app-modal class="success-modal provide-modal">
    <app-modal-image>
      <img src="@/assets/img/svg/login-img.svg" alt="data-img" />
    </app-modal-image>
    <app-modal-title class="popup__title">
      {{ t("provide-phrase-1") }}
    </app-modal-title>
    <div class="popup__msg">
      {{ t("provide-phrase-2") }}
    </div>

    <app-btn :to="userEditAccountLink()" @click="$emit(EventsEnum.CLOSE)">
      {{ t("provide-phrase-3") }}
    </app-btn>
  </app-modal>
</template>
