<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="35" cy="35" r="35" fill="url(#paint0_linear_2_27)" />
    <path
      d="M23.8696 37.0923L27.8625 48.1439C27.8625 48.1439 28.3617 49.1779 28.8962 49.1779C29.4308 49.1779 37.3814 40.9067 37.3814 40.9067L46.2227 23.8298L24.0121 34.2395L23.8696 37.0923Z"
      fill="#C8DAEA"
    />
    <path
      d="M29.164 39.9264L28.3975 48.0725C28.3975 48.0725 28.0767 50.5685 30.5722 48.0725C33.0677 45.5764 35.4563 43.6517 35.4563 43.6517"
      fill="#A9C6D8"
    />
    <path
      d="M23.9416 37.4866L15.7282 34.8105C15.7282 34.8105 14.7466 34.4122 15.0627 33.5092C15.1278 33.323 15.259 33.1645 15.6517 32.8922C17.4715 31.6237 49.336 20.1707 49.336 20.1707C49.336 20.1707 50.2357 19.8676 50.7663 20.0692C50.8976 20.1098 51.0157 20.1846 51.1086 20.2859C51.2015 20.3871 51.2658 20.5113 51.295 20.6455C51.3523 20.8827 51.3763 21.1267 51.3662 21.3705C51.3637 21.5814 51.3382 21.7769 51.3188 22.0834C51.1247 25.2147 45.3171 48.5845 45.3171 48.5845C45.3171 48.5845 44.9696 49.952 43.7247 49.9988C43.4187 50.0087 43.1139 49.9569 42.8284 49.8464C42.5429 49.736 42.2826 49.5692 42.063 49.356C39.6199 47.2546 31.176 41.5798 29.3101 40.3318C29.268 40.3031 29.2326 40.2658 29.2062 40.2222C29.1797 40.1787 29.163 40.13 29.157 40.0794C29.1309 39.9479 29.2739 39.7849 29.2739 39.7849C29.2739 39.7849 43.9771 26.7157 44.3683 25.3437C44.3986 25.2374 44.2842 25.185 44.1305 25.2315C43.1539 25.5908 26.2251 36.2815 24.3567 37.4613C24.2222 37.502 24.0801 37.5107 23.9416 37.4866V37.4866Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_27"
        x1="35"
        y1="70"
        x2="35"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1D93D2" />
        <stop offset="1" stop-color="#38B0E3" />
      </linearGradient>
    </defs>
  </svg>
</template>
