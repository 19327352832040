<script setup>
import { defineEmits, defineProps, onMounted, ref } from "vue";
import AppBtn from "@/components/slots/buttons/AppBtn";
import { EventsEnum } from "@/utils/enum/general/eventsEnum";

const props = defineProps({
  class: { type: String, required: false, default: () => "success-modal" },
});

const emit = defineEmits([EventsEnum.CLOSE]);

const close = () => {
  emit(EventsEnum.CLOSE);
};

const el = ref();

const swipeBottomHandler = () => {
  emit(EventsEnum.CLOSE);
};

// onClickOutside(el, () => {
//   setTimeout(() => {
//     close();
//   }, 100);
// });

const checkEscPressed = (ev) => {
  if (ev.key === "Escape") {
    window.removeEventListener("keyup", checkEscPressed);
    close();
  }
};

onMounted(() => {
  window.addEventListener("keyup", checkEscPressed);
});
</script>

<template>
  <div
    class="popup-container"
    :class="{ 'z-upper': props.class === 'success-modal' }"
  >
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup" ref="el" :class="props.class">
        <app-btn @click="close" class="popup__close" />
        <div class="popup-swipe" v-touch:swipe.bottom="swipeBottomHandler">
          <span></span>
        </div>
        <slot name="head" />
        <div class="popup__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
