import { ContentRatingMarksMultiplierEnum } from "@/utils/enum/content/contentRatingMarksMultiplierEnum";
import { useBusinessStore } from "@/stores/business";

export const useRatingMark = () => {
  const business = useBusinessStore();

  const convertNumberToMultiplier = (
    val: number
  ): ContentRatingMarksMultiplierEnum | void => {
    switch (val) {
      case 1:
        return ContentRatingMarksMultiplierEnum.ONE;
      case 2:
        return ContentRatingMarksMultiplierEnum.TWO;
      case 5:
        return ContentRatingMarksMultiplierEnum.FIVE;
      case 10:
        return ContentRatingMarksMultiplierEnum.TEN;
      case 20:
        return ContentRatingMarksMultiplierEnum.TWENTY;
      case 50:
        return ContentRatingMarksMultiplierEnum.FIFTY;
    }
  };

  const convertMultiplierToNumber = (
    val: ContentRatingMarksMultiplierEnum
  ): number => {
    switch (val) {
      case ContentRatingMarksMultiplierEnum.ONE:
        return 1;
      case ContentRatingMarksMultiplierEnum.TWO:
        return 2;
      case ContentRatingMarksMultiplierEnum.FIVE:
        return 5;
      case ContentRatingMarksMultiplierEnum.TEN:
        return 10;
      case ContentRatingMarksMultiplierEnum.TWENTY:
        return 20;
      case ContentRatingMarksMultiplierEnum.FIFTY:
        return 50;
      default:
        return 0;
    }
  };

  const checkAllowRatingMarks = () => {
    if (!business.isBusinessConnected) {
      business.setConnectBusinessModalVisible(true);
      return false;
    }
    return true;
  };

  return {
    convertNumberToMultiplier,
    convertMultiplierToNumber,
    checkAllowRatingMarks,
  };
};
