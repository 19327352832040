export enum EventsEnum {
  MESSAGE_HAS_BEEN_READ = "messageRead",
  RECEIVE_MESSAGE_NOTIFICATION = "receiveMessageNotification",
  RECEIVE_APP_NOTIFICATION = "receiveAppNotification",
  MESSAGE_SENT = "messageSent",
  MESSAGE_RECEIVED = "messageReceived",
  MESSAGE_DELETED = "messageDeleted",
  CHANGE = "change",
  GET_OR_CREATE_CHAT = "getOrCreateChat",
  USER_CHANGED_CHAT = "userChangedChat",
  CLICK = "click",
  FILES_SELECTED = "filesSelected",
  UNSET_FILE = "unsetFile",
  PREPARE_TO_ANSWER = "prepareToAnswer",
  REPLY_ADDED_TO_CONVERSATION = "replyAddedToConversation",
  COMMENT_HAS_BEEN_ADDED = "commentHasBeenAdded",
  COMMENT_HAS_BEEN_DELETED = "commentHasBeenDeleted",
  CLOSE = "close",
  OPEN = "open",
  MESSAGE = "message",
  OPEN_SUBSCRIPTION_SETTINGS_MODAL = "openSubscriptionSettingsModal",
  OPEN_SUBSCRIBERS_MODAL = "openSubscribersModal",
  OPEN_SUBSCRIPTIONS_MODAL = "openSubscriptionsModal",
  OPEN_REPORT_MODAL = "report",
  OPEN_DELETE_MODAL = "delete",
  GO_BACK = "goBack",
  FOLLOW_ACTION = "followAction",
  DELETE_FOLLOWER = "deleteFollower",
  CHAT_DELETED = "chatDeleted",
  ROOT_SOCKETS_CONNECTIONS_ESTABLISHED = "rootSocketsConnectionsEstablished",
  SHARE = "share",
  DELETE_FILE = "deleteFile",
  REFRESH_CONTENT_TYPE = "refreshContentType",
  SWITCH_FULL_SCREEN = "switchFullscreen",
  OPEN_FIXED_PRICE_MODAL = "openFixedPriceModal",
  OPEN_OPEN_FOR_BIDS_MODAL = "openOpenForBidsModal",
  OPEN_TIME_AUCTION_MODAL = "openTimeAuctionModal",
  OPEN_BUY_AT_SIMPLE_AUCTION_MODAL = "openBuyAtSimpleAuctionModal",
  OPEN_TRANSFER_MODAL = "openTransferModal",
  OPEN_PLACE_BID_ON_OFB_AUCTION_MODAL = "openPlaceBidOnOfbAuctionModal",
  OPEN_PLACE_BID_ON_TIME_AUCTION_MODAL = "openPlaceBidOnTimeAuctionModal",
  OPEN_PUT_FOR_SALE_MODAL = "openPutForSaleModal",
  OPEN_CONFIRM_ACTION_MODAL = "openConfirmActionModal",
  NEXT = "next",
  TWO_FA_SECRET_LOADED = "twoFaSecretLoaded",
  SUCCESS = "success",
  RATING_MARKS_ADDED = "ratingMarksAdded",
  RATING_MARKS_CANCELED = "ratingMarksCanceled",
  SELECT_WINNER_BID_ON_OFB_AUCTION = "selectWinnerBidOnOfbAuction",
  CANCEL_BID_ON_OFB_AUCTION = "cancelBidOnOfbAuction",
  END = "end",
  DELETE = "delete",
  NOTIFICATION_HAS_BEEN_READ = "notificationHasBeenRead",
  RETRY_LOADING = "retryLoading",
  CHANGE_USER_PROFILE_CONTENT_SORT = "changeUserProfileContentSort",
  MAIN_FILE_UPLOADING = "mainFileUploading",
  PREVIEW_FILE_UPLOADING = "previewFileUploading",
  NEED_LOGIN = "needLogin",
  TOGGLE_FILTER_TO_PUBLICATIONS = "toggleFilterToPublications",
  TOGGLE_FILTER_TO_NFTS = "toggleFilterToNfts",
  TOGGLE_FILTER_TO_ALL = "toggleFilterToAll",
  LOADING = "loading",
  CLOSE_WITHDRAW = "closeWithdraw",
  NEED_PROVIDE_PERSONAL_INFO = "needProvidePersonalInfo",
}
