<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 3.33333H2.66665V12.6667L8.86131 6.47067C8.98633 6.34569 9.15587 6.27548 9.33265 6.27548C9.50942 6.27548 9.67896 6.34569 9.80398 6.47067L13.3333 10.0067V3.33333ZM1.33331 2.662C1.33453 2.48692 1.40458 2.31934 1.52832 2.19548C1.65206 2.07161 1.81957 2.0014 1.99465 2H14.0053C14.3706 2 14.6666 2.29667 14.6666 2.662V13.338C14.6654 13.5131 14.5954 13.6807 14.4716 13.8045C14.3479 13.9284 14.1804 13.9986 14.0053 14H1.99465C1.81919 13.9998 1.65098 13.93 1.52697 13.8059C1.40297 13.6817 1.33331 13.5135 1.33331 13.338V2.662ZM5.33331 7.33333C4.97969 7.33333 4.64055 7.19286 4.3905 6.94281C4.14046 6.69276 3.99998 6.35362 3.99998 6C3.99998 5.64638 4.14046 5.30724 4.3905 5.05719C4.64055 4.80714 4.97969 4.66667 5.33331 4.66667C5.68693 4.66667 6.02607 4.80714 6.27612 5.05719C6.52617 5.30724 6.66665 5.64638 6.66665 6C6.66665 6.35362 6.52617 6.69276 6.27612 6.94281C6.02607 7.19286 5.68693 7.33333 5.33331 7.33333Z"
      fill="#ACADB2"
    />
  </svg>
</template>
