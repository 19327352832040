import { defineStore } from "pinia";

export const useNavigationStore = defineStore({
  id: "navigation",
  state: () => ({
    homeStackRouteViewKey: 0,
    headerStackRouteViewKey: 0,
    rootStackRouteViewKey: 0,
  }),
  actions: {
    updateHomeStackRouterView() {
      this.homeStackRouteViewKey = Date.now();
    },
    updateHeaderStackRouterView() {
      this.headerStackRouteViewKey = Date.now();
    },
    updateRootStackRouterView() {
      this.rootStackRouteViewKey = Date.now();
    },
  },
});
