export enum SuccessesEnum {
  REGISTRATION_CONFIRMATION_EMAIL_SENT = "1-s-fe",
  ACCOUNT_ACTIVATED = "2-s-fe",
  USER_INFO_UPDATED = "3-s-fe",
  PUBLICATION_CREATED = "4-s-fe",
  POST_SHARED = "5-s-fe",
  REPORT_SUBMITTED = "6-s-fe",
  BUSINESS_CONNECTED = "7-s-fe",
  PASSWORD_CHANGED = "8-s-fe",
  ADVERTISING_CREATED = "9-s-fe",
  SUBSCRIPTION_PURCHASED = "10-s-fe",
  WITHDRAW_REQUEST_SENT = "11-s-fe",
  TRANSFER_SUCCESS = "12-s-fe",
  AGREEMENT_CONFIRMED = "13-s-fe",
}
