import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { GetUsdPricesResponse } from "@/types/responses/utilController/GetUsdPricesResponse";
import { GetCurrencyMaticPriceRequest } from "@/types/requests/utilController/GetCurrencyMaticPriceRequest";
import { GetCountriesAndCitiesResponse } from "@/types/responses/utilController/GetCountriesAndCitiesResponse";

class UtilApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/util`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getUsdPrices(): Promise<[null, GetUsdPricesResponse[]] | [unknown]> {
    return this.axiosCall<GetUsdPricesResponse[]>({
      method: "get",
      url: "/price/all",
    });
  }

  async getCurrencyMaticPrice(
    payload: GetCurrencyMaticPriceRequest
  ): Promise<[null, number] | [unknown]> {
    return this.axiosCall<number>({
      method: "get",
      url: "/price/matic",
      params: payload,
    });
  }

  async getCountriesAndCities(): Promise<
    [null, GetCountriesAndCitiesResponse] | [unknown]
  > {
    return this.axiosCall<GetCountriesAndCitiesResponse>({
      method: "get",
      url: process.env.VUE_APP_API_COUNTRY_SNOW_URL,
    });
  }
}

export const utilApi = new UtilApiService();
