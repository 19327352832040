import { AppThemeEnum } from "@/utils/enum/theme/appThemeEnum";
import { ref } from "vue";

const theme = ref<AppThemeEnum>(AppThemeEnum.DARK);

export const useTheme = () => {
  const appointTheme = (): AppThemeEnum => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      if (!Object.values(AppThemeEnum)?.includes(theme as AppThemeEnum)) {
        return AppThemeEnum.DARK;
      }
      return theme as AppThemeEnum;
    }
    return AppThemeEnum.DARK;
  };

  const setTheme = (themeVal: AppThemeEnum) => {
    localStorage.setItem("theme", themeVal);
    switch (themeVal) {
      case AppThemeEnum.LIGHT:
        document.body.classList.remove("dark-theme");
        theme.value = AppThemeEnum.LIGHT;
        break;
      case AppThemeEnum.DARK:
        document.body.classList.add("dark-theme");
        theme.value = AppThemeEnum.DARK;
        break;
    }
  };
  return {
    appointTheme,
    setTheme,
    theme,
  };
};
