import { RouteRecordRaw } from "vue-router";
import AuthGuard from "@/router/guards/AuthGuard";
import { RoutesNamesEnum } from "@/utils/enum/navigation/routesNamesEnum";
import { RoutesPathEnum } from "@/utils/enum/navigation/routesPathEnum";
import { ContentStackGuard } from "@/router/guards/ContentStackGuard";
import { AutoAuthGuard } from "@/router/guards/AutoAuthGuard";

const language = localStorage.getItem("lang");

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutesPathEnum.APP_ROOT_PATH,
    redirect: `/${language ? `${language}/` : ""}${
      RoutesPathEnum.RECOMMENDATIONS_PATH
    }`,
  },
  {
    name: RoutesNamesEnum.LANGUAGE_STACK,
    path: RoutesPathEnum.LANGUAGE_STACK_PATH,
    components: {
      RootStackRouterView: () =>
        //import("@/views/rootStack/TechnicalWorksView.vue"),
        import(
          /*webpackChunkName: "language-stack"*/ "@/components/stacks/LanguageStack.vue"
        ),
    },
    children: [
      {
        name: RoutesNamesEnum.HEADER_STACK,
        path: RoutesPathEnum.APP_EMPTY_PATH,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "header-stack"*/ "@/components/stacks/HeaderStack.vue"
            ),
        },
        children: [
          {
            name: RoutesNamesEnum.SIDEBAR_STACK,
            path: RoutesPathEnum.APP_EMPTY_PATH,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "sidebar-stack"*/ "@/components/stacks/SidebarStack.vue"
                ),
            },
            children: [
              {
                name: RoutesNamesEnum.HOME_STACK,
                path: RoutesPathEnum.APP_EMPTY_PATH,
                props: true,
                components: {
                  SidebarStackView: () =>
                    import(
                      /*webpackChunkName: "home-stack"*/ "@/components/stacks/HomeStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.MARKETPLACE_ROUTE_NAME,
                    path: RoutesPathEnum.MARKETPLACE_PATH,
                    components: {
                      HomeStackView: () =>
                        import(
                          /*webpackChunkName: "marketplace"*/ "@/views/sidebarStack/homeStack/MarketplaceView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.RECOMMENDATIONS_ROUTE_NAME,
                    path: RoutesPathEnum.RECOMMENDATIONS_PATH,
                    alias: RoutesPathEnum.RECOMMENDATIONS_ALIAS_PATH,
                    beforeEnter: AutoAuthGuard,
                    components: {
                      HomeStackView: () =>
                        import(
                          /*webpackChunkName: "recommendations"*/ "@/views/sidebarStack/homeStack/RecommendationsView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.SUBSCRIPTIONS_ROUTE_NAME,
                    path: RoutesPathEnum.FOLLOWING_RECOMMENDATIONS_PATH,
                    components: {
                      HomeStackView: () =>
                        import(
                          /*webpackChunkName: "followings-recommendations"*/ "@/views/sidebarStack/homeStack/FollowingsRecommendationView.vue"
                        ),
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.SEARCH_STACK,
                path: RoutesPathEnum.SEARCH_STACK_PATH,
                components: {
                  SidebarStackView: () =>
                    import(
                      /*webpackChunkName: "search"*/ "@/components/stacks/SearchStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.SEARCH_USERS_ROUTE_NAME,
                    path: RoutesPathEnum.SEARCH_USERS_PATH,
                    components: {
                      SearchStackView: () =>
                        import(
                          /*webpackChunkName: "search-users"*/ "@/views/sidebarStack/searchStack/SearchUsersView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.SEARCH_PUBLICATIONS_ROUTE_NAME,
                    path: RoutesPathEnum.SEARCH_PUBLICATIONS_PATH,
                    components: {
                      SearchStackView: () =>
                        import(
                          /*webpackChunkName: "search-publications"*/ "@/views/sidebarStack/searchStack/SearchPublicationsView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.SEARCH_NFTS_ROUTE_NAME,
                    path: RoutesPathEnum.SEARCH_NFTS_PATH,
                    components: {
                      SearchStackView: () =>
                        import(
                          /*webpackChunkName: "search-nfts"*/ "@/views/sidebarStack/searchStack/SearchNftsView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.SEARCH_CATEGORIES_ROUTE_NAME,
                    path: RoutesPathEnum.SEARCH_CATEGORIES_PATH,
                    components: {
                      SearchStackView: () =>
                        import(
                          /*webpackChunkName: "search-categories"*/ "@/views/sidebarStack/searchStack/SearchCategoriesView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.SEARCH_TAGS_ROUTE_NAME,
                    path: RoutesPathEnum.SEARCH_TAGS_PATH,
                    components: {
                      SearchStackView: () =>
                        import(
                          /*webpackChunkName: "search-tags"*/ "@/views/sidebarStack/searchStack/SearchTagsView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.USER_PROFILE_STACK,
                path: RoutesPathEnum.USER_PROFILE_STACK_PATH,
                props: true,
                components: {
                  SidebarStackView: () =>
                    import(
                      /*webpackChunkName: "user-profile"*/ "@/components/stacks/UserProfileStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.USER_PROFILE_ALL_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_ALL_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-all"*/ "@/views/sidebarStack/userProfileStack/AllUserContentView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.USER_PROFILE_PUBLICATIONS_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_PUBLICATIONS_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-publications"*/ "@/views/sidebarStack/userProfileStack/UserPublicationsView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.USER_PROFILE_NFTS_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_NFTS_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-nft"*/ "@/views/sidebarStack/userProfileStack/UserNftView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.USER_PROFILE_ON_SALE_NFTS_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_ON_SALE_NFTS_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-on-sale"*/ "@/views/sidebarStack/userProfileStack/UserNftOnSaleView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.USER_PROFILE_OWNED_NFTS_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_OWNED_NFTS_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-owned"*/ "@/views/sidebarStack/userProfileStack/UserNftOwnedView.vue"
                        ),
                    },
                  },
                  {
                    name: RoutesNamesEnum.USER_PROFILE_CREATED_NFTS_CONTENT_ROUTE_NAME,
                    path: RoutesPathEnum.USER_PROFILE_CREATED_NFTS_CONTENT_PATH,
                    props: true,
                    components: {
                      UserProfileStackView: () =>
                        import(
                          /*webpackChunkName: "user-profile-created"*/ "@/views/sidebarStack/userProfileStack/UserNftCreatedView.vue"
                        ),
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.ANALYTICS_ROUTE_NAME,
                path: RoutesPathEnum.ANALYTICS_PATH,
                components: {
                  SidebarStackView: () =>
                    import(
                      /*webpackChunkName: "user-profile"*/ "@/views/sidebarStack/AnalyticsView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            name: RoutesNamesEnum.USER_ACCOUNT_STACK,
            path: RoutesPathEnum.USER_ACCOUNT_STACK_PATH,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "user-account-stack"*/ "@/components/stacks/UserAccountStack.vue"
                ),
            },
            children: [
              {
                name: RoutesNamesEnum.ACCOUNT_EDIT_ROUTE_NAME,
                path: RoutesPathEnum.EDIT_ACCOUNT_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "user-account-edit"*/ "@/views/headerStack/userAccountStack/AccountEditView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.ACCOUNT_SUBSCRIPTIONS_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_SUBSCRIPTIONS_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "user-account-subscriptions"*/ "@/views/headerStack/userAccountStack/AccountSubscriptionsView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.BALANCES_STACK,
                path: RoutesPathEnum.BALANCES_STACK_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "balances-stack"*/ "@/components/stacks/BalanceStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.ACCOUNT_BALANCES_ROUTE_NAME,
                    path: RoutesPathEnum.APP_EMPTY_PATH,
                    components: {
                      BalancesStackView: () =>
                        import(
                          /*webpackChunkName: "balances"*/ "@/views/headerStack/userAccountStack/balanceStack/BalancesView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_YRM_TX_HISTORY_ROUTE_NAME,
                    path: RoutesPathEnum.ACCOUNT_YRM_TX_HISTORY_PATH,
                    components: {
                      BalancesStackView: () =>
                        import(
                          /*webpackChunkName: "transactions-history"*/ "@/views/headerStack/userAccountStack/balanceStack/TransactionsHistoryView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.TRANSACTIONS_STACK,
                path: RoutesPathEnum.TRANSACTIONS_STACK_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "transactions-stack"*/ "@/components/stacks/DepositWithdrawHistoryStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.ACCOUNT_TX_HISTORY_ROUTE_NAME,
                    path: RoutesPathEnum.APP_EMPTY_PATH,
                    components: {
                      DepositWithdrawStackView: () =>
                        import(
                          /*webpackChunkName: "tx-history"*/ "@/views/headerStack/userAccountStack/depositWithdrawHistoryStack/TxHistoryView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_WITHDRAW_REQUESTS_ROUTE_NAME,
                    path: RoutesPathEnum.ACCOUNT_WITHDRAW_REQUESTS_PATH,
                    components: {
                      DepositWithdrawStackView: () =>
                        import(
                          /*webpackChunkName: "withdraw-requests"*/ "@/views/headerStack/userAccountStack/depositWithdrawHistoryStack/WithdrawRequestsView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.ACCOUNT_PAYMENT_HISTORY_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_PAYMENT_HISTORY_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "payment-history"*/ "@/views/headerStack/userAccountStack/AccountPaymentHistoryView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.PROMOTION_STACK,
                path: RoutesPathEnum.PROMOTION_STACK_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "promotion-stack"*/ "@/components/stacks/UserAccountPromotionStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.ACCOUNT_PROMOTION_ROUTE_NAME,
                    path: RoutesPathEnum.APP_EMPTY_PATH,
                    redirect: `${RoutesPathEnum.USER_ACCOUNT_STACK_PATH}/${RoutesPathEnum.PROMOTION_STACK_PATH}/${RoutesPathEnum.PUBLICATIONS_PATH}`,
                    components: {
                      UserAccountPromotionStackView: () =>
                        import(
                          /*webpackChunkName: "account-promotion"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountPromotionView.vue"
                        ),
                    },
                    children: [
                      {
                        name: RoutesNamesEnum.ACCOUNT_PROMOTION_PUBLICATIONS_ROUTE_NAME,
                        path: RoutesPathEnum.PUBLICATIONS_PATH,
                        component: () =>
                          import(
                            /*webpackChunkName: "account-promotion-publications"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountPromotionPublications.vue"
                          ),
                      },
                      {
                        name: RoutesNamesEnum.ACCOUNT_PROMOTION_PROFILE_ROUTE_NAME,
                        path: RoutesPathEnum.PROFILES_PATH,
                        component: () =>
                          import(
                            /*webpackChunkName: "account-promotion-profiles"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountPromotionProfiles.vue"
                          ),
                      },
                    ],
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.PROFILE_PROMOTION_ROUTE_NAME,
                    path: RoutesPathEnum.PROFILE_PROMOTION_PATH,
                    components: {
                      UserAccountPromotionStackView: () =>
                        import(
                          /*webpackChunkName: "user-account-promotion-profile"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountProfilePromotionView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.PUBLICATION_PROMOTION_ROUTE_NAME,
                    path: RoutesPathEnum.PUBLICATION_PROMOTION_PATH,
                    components: {
                      UserAccountPromotionStackView: () =>
                        import(
                          /*webpackChunkName: "user-account-promotion-publication"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountPublicationPromotionView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_PUBLICATION_STATS_ROUTE_NAME,
                    path: `${RoutesPathEnum.PUBLICATION_PROMOTION_PATH}/${RoutesPathEnum.PUBLICATION_STATS_PATH}`,
                    props: true,
                    components: {
                      UserAccountPromotionStackView: () =>
                        import(
                          /*webpackChunkName: "publication-stats"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountPublicationStats.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_PROFILE_STATS_ROUTE_NAME,
                    path: `${RoutesPathEnum.PROFILE_PROMOTION_PATH}/${RoutesPathEnum.PUBLICATION_STATS_PATH}`,
                    props: true,
                    components: {
                      UserAccountPromotionStackView: () =>
                        import(
                          /*webpackChunkName: "profile-stats"*/ "@/views/headerStack/userAccountStack/userAccountPromotionStack/UserAccountProfileStats.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.ACCOUNT_SECURITY_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_SECURITY_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "security"*/ "@/views/headerStack/userAccountStack/AccountSecurityView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.ACCOUNT_NOTIFICATIONS_SETTINGS_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_NOTIFICATION_SETTINGS_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "user-account-notification"*/ "@/views/headerStack/userAccountStack/AccountNotificationsView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.VERIFICATION_STACK,
                path: RoutesPathEnum.VERIFICATION_STACK_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "verification-stack"*/ "@/components/stacks/UserAccountVerificationStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.ACCOUNT_VERIFICATION_ROUTE_NAME,
                    path: RoutesPathEnum.APP_EMPTY_PATH,
                    components: {
                      UserAccountVerificationStack: () =>
                        import(
                          /*webpackChunkName: "verification"*/ "@/views/headerStack/userAccountStack/userAccountVerificationStack/AccountVerificationView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_VERIFICATION_SUCCESS_ROUTE_NAME,
                    path: RoutesPathEnum.APP_SUCCESS_PATH,
                    components: {
                      UserAccountVerificationStack: () =>
                        import(
                          /*webpackChunkName: "verification-success"*/ "@/views/headerStack/userAccountStack/userAccountVerificationStack/VerificationSuccessView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_VERIFICATION_PENDING_ROUTE_NAME,
                    path: RoutesPathEnum.APP_PENDING_PATH,
                    components: {
                      UserAccountVerificationStack: () =>
                        import(
                          /*webpackChunkName: "verification-pending"*/ "@/views/headerStack/userAccountStack/userAccountVerificationStack/VerificationPendingView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_VERIFICATION_FAILED_ROUTE_NAME,
                    path: RoutesPathEnum.APP_FAILED_PATH,
                    components: {
                      UserAccountVerificationStack: () =>
                        import(
                          /*webpackChunkName: "verification-failed"*/ "@/views/headerStack/userAccountStack/userAccountVerificationStack/VerificationFailedView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.IDENTITY_STACK,
                path: RoutesPathEnum.IDENTITY_STACK_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "identity-stack"*/ "@/components/stacks/UserAccountIdentityStack.vue"
                    ),
                },
                children: [
                  {
                    name: RoutesNamesEnum.ACCOUNT_IDENTITY_ROUTE_NAME,
                    path: RoutesPathEnum.APP_EMPTY_PATH,
                    components: {
                      UserAccountIdentityStack: () =>
                        import(
                          /*webpackChunkName: "identity"*/ "@/views/headerStack/userAccountStack/userAccountIdentityStack/AccountIdentityView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_IDENTITY_SUCCESS_ROUTE_NAME,
                    path: RoutesPathEnum.APP_SUCCESS_PATH,
                    components: {
                      UserAccountIdentityStack: () =>
                        import(
                          /*webpackChunkName: "identity-success"*/ "@/views/headerStack/userAccountStack/userAccountIdentityStack/AccountIdentitySuccessView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_IDENTITY_PENDING_ROUTE_NAME,
                    path: RoutesPathEnum.APP_PENDING_PATH,
                    components: {
                      UserAccountIdentityStack: () =>
                        import(
                          /*webpackChunkName: "identity-pending"*/ "@/views/headerStack/userAccountStack/userAccountIdentityStack/AccountIdentityPendingView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                  {
                    name: RoutesNamesEnum.ACCOUNT_IDENTITY_FAILED_ROUTE_NAME,
                    path: RoutesPathEnum.APP_FAILED_PATH,
                    components: {
                      UserAccountIdentityStack: () =>
                        import(
                          /*webpackChunkName: "identity-failed"*/ "@/views/headerStack/userAccountStack/userAccountIdentityStack/AccountIdentityFailedView.vue"
                        ),
                    },
                    meta: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                name: RoutesNamesEnum.ACCOUNT_REFERRAL_PROGRAM_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_REFERRAL_PROGRAM_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "user-account-referral"*/ "@/views/headerStack/userAccountStack/AccountReferralProgramView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.ACCOUNT_OFFER_ROUTE_NAME,
                path: RoutesPathEnum.ACCOUNT_OFFER_PATH,
                components: {
                  UserAccountStackView: () =>
                    import(
                      /*webpackChunkName: "user-account-offer"*/ "@/views/headerStack/userAccountStack/AccountAuthorsOfferView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            name: RoutesNamesEnum.CHAT_STACK,
            path: RoutesPathEnum.CHAT_STACK_PATH,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "chat-stack"*/ "@/components/stacks/ChatStack.vue"
                ),
            },
            children: [
              {
                name: RoutesNamesEnum.CHAT_ROUTE_NAME,
                path: RoutesPathEnum.APP_EMPTY_PATH,
                components: {
                  ChatStackView: () =>
                    import(
                      /*webpackChunkName: "chat"*/ "@/views/headerStack/chatStack/ChatView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
              {
                name: RoutesNamesEnum.CHAT_DIALOG_ROUTE_NAME,
                path: RoutesPathEnum.CHAT_DIALOG_PATH,
                props: true,
                components: {
                  ChatStackView: () =>
                    import(
                      /*webpackChunkName: "chat-room"*/ "@/views/headerStack/chatStack/ChatDialogView.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            name: RoutesNamesEnum.CREATE_PUBLICATION_ROUTE_NAME,
            path: RoutesPathEnum.CREATE_PUBLICATION_PATH,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "create-content"*/ "@/views/headerStack/CreatePublicationView.vue"
                ),
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            name: RoutesNamesEnum.EDIT_PUBLICATION_ROUTE_NAME,
            path: RoutesPathEnum.EDIT_PUBLICATION_PARAMS_PATH,
            props: true,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "update-content"*/ "@/views/headerStack/UpdatePublicationView.vue"
                ),
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            name: RoutesNamesEnum.CREATE_NFT_ROUTE_NAME,
            path: RoutesPathEnum.CREATE_NFT_PATH,
            props: true,
            components: {
              HeaderStackView: () =>
                import(
                  /*webpackChunkName: "create-nft"*/ "@/views/headerStack/CreateNftView.vue"
                ),
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        name: RoutesNamesEnum.SIGN_UP_ROUTE_NAME,
        path: `${RoutesPathEnum.SIGN_UP_PATH}/:nickname?`,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "sign-up"*/ "@/views/rootStack/SignUpView.vue"
            ),
        },
        beforeEnter: AuthGuard,
      },
      {
        name: RoutesNamesEnum.SIGN_IN_ROUTE_NAME,
        path: RoutesPathEnum.SIGN_IN_PATH,
        alias: RoutesPathEnum.CONFIRM_REGISTRATION_PATH,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "sign-in"*/ "@/views/rootStack/SignInView.vue"
            ),
        },
        beforeEnter: AuthGuard,
      },
      {
        name: RoutesNamesEnum.RESTORE_PASSWORD_ROUTE_NAME,
        path: RoutesPathEnum.RESTORE_PATH,
        alias: [
          RoutesPathEnum.RESTORE_PASSWORD_PATH,
          RoutesPathEnum.CONFIRM_RESTORE_PASSWORD_PATH,
        ],
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "restore-password"*/ "@/views/rootStack/RestorePasswordView.vue"
            ),
        },
      },
      {
        name: RoutesNamesEnum.SUPPORT_ROUTE_NAME,
        path: RoutesPathEnum.SUPPORT_PATH,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "support"*/ "@/views/rootStack/SupportView.vue"
            ),
        },
      },
      {
        name: RoutesNamesEnum.CONFIRM_LOGIN_ROUTE_NAME,
        path: RoutesPathEnum.CONFIRM_LOGIN_PATH,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "confirm-login"*/ "@/views/rootStack/ConfirmLoginView.vue"
            ),
        },
      },
      {
        name: RoutesNamesEnum.CONTENT_STACK,
        path: RoutesPathEnum.CONTENT_PUBLICATION_STACK_PATH,
        alias: RoutesPathEnum.CONTENT_NFT_STACK_PATH,
        props: true,
        beforeEnter: ContentStackGuard,
        components: {
          LanguageStackRouterView: () =>
            import(
              /*webpackChunkName: "content-stack"*/ "@/components/stacks/ContentStack.vue"
            ),
        },
        children: [
          {
            name: RoutesNamesEnum.CONTENT_DESCRIPTION_ROUTE_NAME,
            path: RoutesPathEnum.CONTENT_DESCRIPTION_PATH,
            props: true,
            components: {
              ContentStackView: () =>
                import(
                  /*webpackChunkName: "content-description"*/ "@/views/rootStack/contentStack/ContentDescriptionView.vue"
                ),
            },
          },
          {
            name: RoutesNamesEnum.CONTENT_BIDS_ROUTE_NAME,
            path: RoutesPathEnum.CONTENT_BIDS_PATH,
            props: true,
            components: {
              ContentStackView: () =>
                import(
                  /*webpackChunkName: "content-bids"*/ "@/views/rootStack/contentStack/ContentBidsView.vue"
                ),
            },
          },
          {
            name: RoutesNamesEnum.CONTENT_OWNERS_ROUTE_NAME,
            path: RoutesPathEnum.CONTENT_OWNERS_PATH,
            props: true,
            components: {
              ContentStackView: () =>
                import(
                  /*webpackChunkName: "content-owners"*/ "@/views/rootStack/contentStack/ContentOwnersView.vue"
                ),
            },
          },
          {
            name: RoutesNamesEnum.CONTENT_HISTORY_ROUTE_NAME,
            path: RoutesPathEnum.CONTENT_HISTORY_PATH,
            props: true,
            components: {
              ContentStackView: () =>
                import(
                  /*webpackChunkName: "content-history"*/ "@/views/rootStack/contentStack/ContentHistoryView.vue"
                ),
            },
          },
          {
            name: RoutesNamesEnum.CONTENT_COMMENTS_ROUTE_NAME,
            path: RoutesPathEnum.CONTENT_COMMENTS_PATH,
            props: true,
            components: {
              ContentStackView: () =>
                import(
                  /*webpackChunkName: "content-comments"*/ "@/views/rootStack/contentStack/ContentCommentsView.vue"
                ),
            },
          },
        ],
      },
    ],
  },
];

export { routes };
