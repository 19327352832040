import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { DocumentTypeEnum } from "@/utils/enum/verification/documentTypeEnum";
import { DocumentSideEnum } from "@/utils/enum/verification/documentSideEnum";
import { UploadDetailsRequest } from "@/types/requests/kycController/UploadDetailsRequest";
import { GetKyc } from "@/types/responses/kycController/GetKyc";

class KycApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/kyc`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getKyc(): Promise<[null, GetKyc] | [unknown]> {
    return this.axiosCall<GetKyc>({
      method: "get",
      url: "/",
    });
  }

  async uploadDocument(
    type: DocumentTypeEnum,
    side: DocumentSideEnum,
    document: FormData
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: `${process.env.VUE_APP_API_UPLOAD_URL}/kyc/document/${type}/${side}`,
      data: document,
    });
  }

  async uploadDetails(
    payload: UploadDetailsRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: `/details`,
      data: payload,
    });
  }

  async sendToVerification(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: `/send_to_verification`,
    });
  }
}

export const kycApi = new KycApiService();
