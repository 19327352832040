import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { DefaultPageableRequest } from "@/types/abstract/pagination/request/DefaultPageableRequest";
import { ReadNotificationRequest } from "@/types/requests/notificationsController/ReadNotificationRequest";
import { DefaultPageableResponse } from "@/types/abstract/pagination/response/DefaultPageableResponse";
import { AppNotification } from "@/types/notification/AppNotification";

class NotificationsApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/notifications`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getUnreadNotifications(
    payload: DefaultPageableRequest
  ): Promise<[null, DefaultPageableResponse<AppNotification>] | [unknown]> {
    return this.axiosCall<DefaultPageableResponse<AppNotification>>({
      method: "get",
      url: "/un_read",
      params: payload.params,
    });
  }

  async getNotificationsCount(): Promise<[null, number] | [unknown]> {
    return this.axiosCall<number>({
      method: "get",
      url: "/count/un_read",
    });
  }

  async readNotification(
    payload: ReadNotificationRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/read",
      params: payload,
    });
  }

  async readAllNotifications(): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: `/read_all`,
    });
  }
}

export const notificationsApi = new NotificationsApiService();
