import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { notificationsApi } from "@/api/notifications.api";

export const useNotificationsStore = defineStore({
  id: "notifications",
  state: () => ({
    notReadNotifications: 0,
  }),
  getters: {
    isNotReadNotificationsExists(): boolean {
      return !!this.notReadNotifications;
    },
  },
  actions: {
    increaseUnreadAppNotificationsQuantity() {
      this.notReadNotifications += 1;
    },
    reduceUnreadAppNotificationsQuantity() {
      this.notReadNotifications -= 1;
    },
    unsetUnreadAppNotificationsQuantity() {
      this.notReadNotifications = 0;
    },
    async getNotificationsCount() {
      const [error, response] = await notificationsApi.getNotificationsCount();

      if (error) {
        await useExceptionHandler(error);
        return;
      }

      if (response) {
        this.notReadNotifications = response;
      }
    },
  },
});
