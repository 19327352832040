export enum ErrorsEnum {
  USER_NOT_FOUND = "3001",
  QUESTION_IS_NOT_SPECIFIED = "1-e-fe",
  WRONG_RESTORE_CODE_PROVIDED = "2-e-fe",
  DEVICE_NOT_SUPPORT_NATIVE_SHARING = "3-e-fe",
  CANNOT_COPY_TO_CLIPBOARD = "4-e-fe",
  EMAIL_IS_NOT_SPECIFIED = "5-e-fe",
  NEED_LOGIN = "6-e-fe",
  UNSUPPORTED_FILE_TYPE = "7-e-fe",
  ONLY_IMAGES_UPLOADING_ALLOWED = "8-e-fe",
  DOCUMENT_TYPE_IS_NOT_SPECIFIED = "9-e-fe",
  COUNTRY_IS_NOT_SPECIFIED = "10-e-fe",
  NOT_ENOUGH_DOCUMENTS_FOR_VERIFICATION = "11-e-fe",
  ONLY_TWO_FILES_REQUIRED = "12-e-fe",
  AVATAR_MAXIMUM_UPLOAD_SIZE_EXCEEDED = "13-e-fe",
  START_DATE_IS_LESS_THAN_CURRENT = "14-e-fe",
  END_DATE_IS_LESS_THAN_CURRENT = "15-e-fe",
  END_DATE_IS_LESS_THAN_START = "16-e-fe",
  END_DATE_IS_EQUALS_TO_START = "17-e-fe",
  NEED_CONNECT_BUSINESS = "18-e-fe",
  VERIFICATION_DOCUMENT_MAXIMUM_UPLOAD_SIZE_EXCEEDED = "19-e-fe",
  NEED_SPECIFY_REPORT_REASON = "20-e-fe",
  CANNOT_CREATE_TRANSACTION = "21-e-fe",
  NEED_IMAGE_LOGIN = "22-e-fe",
  MAX_CLOUDFLARE_FILE_SIZE_EXCEEDED = "23-e-fe",
  INTERNAL_SERVER_ERROR = "24-e-fe",
  UNKNOWN_ERROR_OCCURRED = "25-e-fe",
  AGE_FROM_GREATER_THEN_AGE_TO = "26-e-fe",
  AGE_CATEGORY_IS_NOT_SPECIFIED = "27-e-fe",
  PROMOTION_CONTENT_IS_NOT_SPECIFIED = "28-e-fe",
  INTERESTS_IS_NOT_SPECIFIED = "29-e-fe",
  NEED_DISABLE_ADBLOCK = "30-e-fe",
  PROMOTION_TIME_NOT_SPECIFIED = "31-e-fe",
  PROMOTION_DAYS_NOT_SPECIFIED = "32-e-fe",
}
