import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { ratingMarksApi } from "@/api/ratingMarks.api";

export const useRatingMarkStore = defineStore({
  id: "ratingMark",
  state: () => ({
    booster: null as null | number,
    marks: null as null | number,
    available: null as null | number,
  }),
  getters: {
    getBooster(): number | null {
      return this.booster;
    },
    getMarks(): number | null {
      return this.marks;
    },
    getAvailable(): number | null {
      return this.available;
    },
  },
  actions: {
    async getRatingMarks() {
      const [error, response] = await ratingMarksApi.getRatingMarks();

      if (error) {
        await useExceptionHandler(error);
        return;
      }

      if (response) {
        this.booster = response.booster;
        this.marks = response.marks;
        this.available = response.available;
      }
    },
  },
});
