import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import beforeEachGuard from "@/router/guards/BeforeEachGuard";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ left: 0, top: 0 });
        }
      }, 400);
    });
  },
});
router.beforeEach(beforeEachGuard);

export default router;
