import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";

export const useReferralStore = defineStore({
  id: "referral",
  state: () => ({
    isBusinessConnected: false,
    isPaidSubscription: false,
  }),
  getters: {
    getIsRefLinkAvailable(): boolean {
      return this.isBusinessConnected || this.isPaidSubscription;
    },
    getRefLink(): string {
      const user = useUserStore();
      return `https://beta.yolllo.com/sign-up/${user.getNickname}`;
    },
  },
  actions: {
    setIsBusinessConnected(val: boolean) {
      this.isBusinessConnected = val;
    },
    setIsPaidSubscription(val: boolean) {
      this.isPaidSubscription = val;
    },
  },
});
