<script setup>
import AppModal from "@/components/slots/modals/AppModal";
import AppModalImage from "@/components/slots/modals/AppModalImage";
import AppModalText from "@/components/slots/modals/AppModalText";
import AppModalMessage from "@/components/slots/modals/AppModalMessage";
import { useGeneralStore } from "@/stores/general";
import { useI18n } from "vue-i18n";

const general = useGeneralStore();
const { t } = useI18n();
</script>

<template>
  <app-modal @close="general.setSuccess(null)">
    <app-modal-image>
      <img src="@/assets/img/svg/success-modal-img.svg" alt="check-icon" />
    </app-modal-image>
    <app-modal-text> Thank you! </app-modal-text>
    <app-modal-message> {{ t(general.getSuccess) }} </app-modal-message>
  </app-modal>
</template>
