// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SockJs from "sockjs-client";
import Stomp, { Client, Message, Subscription } from "webstomp-client";
import { UserReadMessageRequest } from "@/types/socket/chat/requests/UserReadMessageRequest";
import { ConnectToSocketRequest } from "@/types/socket/common/ConnectToSocketRequest";
import { SubscribeReceiveMessagesRequest } from "@/types/socket/chat/requests/SubscribeReceiveMessagesRequest";

export class ChatSocketService {
  private static instance: ChatSocketService;
  static chatSocket: Client;

  private constructor() {
    const socket = new SockJs(
      `${process.env.VUE_APP_API_CHAT_URL}/ws-chat`,
      null,
      {}
    );
    ChatSocketService.chatSocket = Stomp.over(socket, { debug: false });
  }
  public static getInstance(): ChatSocketService {
    if (!ChatSocketService.instance) {
      ChatSocketService.instance = new ChatSocketService();
    }

    return ChatSocketService.instance;
  }

  public static connect(payload: ConnectToSocketRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      this.chatSocket.connect(
        { Authorization: payload.bearer },
        () => {
          resolve();
        },
        () => {
          reject();
        }
      );
    });
  }

  public static disconnect() {
    if (this.chatSocket?.connected) {
      this.chatSocket.disconnect();
    }
  }

  public static subscribeToChatNotifications(
    payload: SubscribeReceiveMessagesRequest
  ) {
    if (!this.chatSocket.connected) {
      return;
    }
    this.chatSocket.subscribe(`/users/${payload.userId}`, (tick: Message) => {
      payload.cb(JSON.parse(tick.body));
    });
  }

  public static subscribeToChatMessages(
    payload: UserReadMessageRequest
  ): Subscription | null {
    if (!this.chatSocket.connected) {
      return null;
    }
    return this.chatSocket.subscribe(
      `/chat/messages/${payload.chatId}`,
      (tick: Message) => {
        payload.cb(JSON.parse(tick.body));
      }
    );
  }

  public static unsubscribeFromUserReadMessages(payload: string) {
    this.chatSocket.unsubscribe(payload);
  }
}
