<template>
  <div class="preloader">
    <div class="preloader-wrap preloader-light">
      <Vue3Lottie :animationData="PreloaderJSON" :height="200" :width="200" />
    </div>
    <div class="preloader-wrap preloader-dark">
      <Vue3Lottie
        :animationData="PreloaderDarkJSON"
        :height="200"
        :width="200"
      />
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import PreloaderJSON from "@/plugins/lottie/preloader.json";
import PreloaderDarkJSON from "@/plugins/lottie/preloader-dark.json";

export default {
  name: "AppPreloader",
  data() {
    return {
      PreloaderJSON,
      PreloaderDarkJSON,
    };
  },
  components: {
    Vue3Lottie,
  },
};
</script>

<style scoped lang="scss">
.preloader {
  position: fixed;
  z-index: 99994;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #fff;

  .text {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 30px;
  }
}
.preloader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-dark {
  display: none;
}

.dark-theme {
  .preloader-light {
    display: none;
  }

  .preloader-dark {
    display: flex;
  }

  .preloader {
    background: #000000;
  }
}
</style>
