import { ref } from "vue";

export const useToggleVisible = () => {
  const visible = ref(false);
  const openParams = ref<unknown>(null);

  const toggle = () => {
    visible.value = !visible.value;
  };

  const open = (event?: unknown) => {
    openParams.value = event;
    visible.value = true;
  };

  const close = () => {
    visible.value = false;
  };

  return { toggle, visible, open, close, openParams };
};
