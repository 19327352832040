import { useLanguageStore } from "@/stores/language";
import { RoutesPathEnum } from "@/utils/enum/navigation/routesPathEnum";

const useLink = () => {
  const language = useLanguageStore();

  const signInLink = (isRedirect = false) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SIGN_IN_PATH
    }${isRedirect ? "?redirect=true" : ""}`;
  };

  const signUpLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SIGN_UP_PATH
    }`;
  };

  const homeLink = () => {
    return recommendationsLink();
  };

  const restoreLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.RESTORE_PATH
    }`;
  };

  const supportLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SUPPORT_PATH
    }`;
  };

  const searchUsersLink = (searchValue: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SEARCH_STACK_PATH
    }/${RoutesPathEnum.SEARCH_USERS_PATH}?search=${searchValue}`;
  };

  const searchCategoriesLink = (searchValue: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SEARCH_STACK_PATH
    }/${RoutesPathEnum.SEARCH_CATEGORIES_PATH}?search=${searchValue}`;
  };

  const searchPublicationsLink = (searchValue: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SEARCH_STACK_PATH
    }/${RoutesPathEnum.SEARCH_PUBLICATIONS_PATH}?search=${searchValue}`;
  };

  const searchNftsLink = (searchValue: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SEARCH_STACK_PATH
    }/${RoutesPathEnum.SEARCH_NFTS_PATH}?search=${searchValue}`;
  };

  const searchTagsLink = (searchValue: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.SEARCH_STACK_PATH
    }/${RoutesPathEnum.SEARCH_TAGS_PATH}?search=${searchValue}`;
  };

  const userEditAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.EDIT_ACCOUNT_PATH}`;
  };

  const userSubscriptionsAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.ACCOUNT_SUBSCRIPTIONS_PATH}`;
  };

  const userPromotionPublicationsAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.PROMOTION_STACK_PATH}/${
      RoutesPathEnum.PUBLICATIONS_PATH
    }`;
  };

  const userPromotionProfilesAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.PROMOTION_STACK_PATH}/${RoutesPathEnum.PROFILES_PATH}`;
  };

  const userProfilePromotionAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.PROMOTION_STACK_PATH}/${
      RoutesPathEnum.PROFILE_PROMOTION_PATH
    }`;
  };

  const userPublicationPromotionAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.PROMOTION_STACK_PATH}/${
      RoutesPathEnum.PUBLICATION_PROMOTION_PATH
    }`;
  };

  const userSecurityAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.ACCOUNT_SECURITY_PATH}`;
  };

  const userNotificationAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.ACCOUNT_NOTIFICATION_SETTINGS_PATH}`;
  };

  const userVerificationAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.VERIFICATION_STACK_PATH}`;
  };

  const userVerificationSuccessAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.VERIFICATION_STACK_PATH}/${
      RoutesPathEnum.APP_SUCCESS_PATH
    }`;
  };

  const userVerificationPendingAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.VERIFICATION_STACK_PATH}/${
      RoutesPathEnum.APP_PENDING_PATH
    }`;
  };

  const userVerificationFailedAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.VERIFICATION_STACK_PATH}/${
      RoutesPathEnum.APP_FAILED_PATH
    }`;
  };

  const userIdentityAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.IDENTITY_STACK_PATH}`;
  };

  const userIdentitySuccessAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.IDENTITY_STACK_PATH}/${RoutesPathEnum.APP_SUCCESS_PATH}`;
  };

  const userIdentityPendingAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.IDENTITY_STACK_PATH}/${RoutesPathEnum.APP_PENDING_PATH}`;
  };

  const userIdentityFailedAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.IDENTITY_STACK_PATH}/${RoutesPathEnum.APP_FAILED_PATH}`;
  };

  const userReferralAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.ACCOUNT_REFERRAL_PROGRAM_PATH}`;
  };

  const userOfferAccountLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.USER_ACCOUNT_STACK_PATH
    }/${RoutesPathEnum.ACCOUNT_OFFER_PATH}`;
  };

  const userProfileLink = (nickname: string, filter = "all") => {
    if (!nickname) {
      return "";
    }
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }user/${nickname}/${filter}`;
  };

  const chatRoomLink = (chatRoomId?: string) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.CHAT_STACK_PATH
    }${chatRoomId ? "/" + chatRoomId : ""}`;
  };

  const updateContentLink = (contentWebId: number) => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}${
      RoutesPathEnum.EDIT_PUBLICATION_PATH
    }/${contentWebId}`;
  };

  const contentDescriptionLink = (
    contentId: number,
    contractAddress?: string
  ) => {
    if (contractAddress) {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/nft/${contractAddress}/${contentId}/description`;
    } else {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/${contentId}/description`;
    }
  };

  const contentBidsLink = (contentId: number, contractAddress?: string) => {
    if (contractAddress) {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/nft/${contractAddress}/${contentId}/bids`;
    } else {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/${contentId}/bids`;
    }
  };

  const contentOwnersLink = (contentId: number, contractAddress?: string) => {
    if (contractAddress) {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/nft/${contractAddress}/${contentId}/owners`;
    } else {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/${contentId}/owners`;
    }
  };

  const contentHistoryLink = (contentId: number, contractAddress?: string) => {
    if (contractAddress) {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/nft/${contractAddress}/${contentId}/history`;
    } else {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/${contentId}/history`;
    }
  };

  const contentCommentsLink = (contentId: number, contractAddress?: string) => {
    if (contractAddress) {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/nft/${contractAddress}/${contentId}/comments`;
    } else {
      return `/${
        language.getLanguage ? `${language.getLanguage}/` : ""
      }assets/${contentId}/comments`;
    }
  };

  const createNftLink = (publicationId: string) => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }create-nft/${publicationId}`;
  };

  const createPublicationLink = () => {
    return `/${language.getLanguage ? `${language.getLanguage}/` : ""}create`;
  };

  const analyticsLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }analytics`;
  };

  const balancesLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/balances`;
  };

  const depositWithdrawHistoryLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/transactions`;
  };

  const withdrawRequestLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/transactions/withdraw`;
  };

  const paymentHistoryLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/payment`;
  };

  const transactionsHistoryLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/balances/history`;
  };

  const publicationStatsLink = (advertisingId: number) => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/promotion/publication/stats/${advertisingId}`;
  };

  const profileStatsLink = (advertisingId: number) => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }account/promotion/profile/stats/${advertisingId}`;
  };

  const marketplaceLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }nft-marketplace`;
  };

  const recommendationsLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }recommendations-feed`;
  };

  const followingRecommendationsLink = () => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }subscriptions-feed`;
  };

  const confirmLoginLink = (email: string) => {
    return `/${
      language.getLanguage ? `${language.getLanguage}/` : ""
    }confirm-login?email=${email}`;
  };

  return {
    signInLink,
    homeLink,
    signUpLink,
    restoreLink,
    supportLink,
    chatRoomLink,
    userProfileLink,
    createNftLink,
    searchUsersLink,
    searchCategoriesLink,
    searchTagsLink,
    searchPublicationsLink,
    searchNftsLink,
    analyticsLink,
    transactionsHistoryLink,
    balancesLink,
    depositWithdrawHistoryLink,
    withdrawRequestLink,
    paymentHistoryLink,
    marketplaceLink,
    recommendationsLink,
    followingRecommendationsLink,
    userEditAccountLink,
    userSubscriptionsAccountLink,
    userPromotionPublicationsAccountLink,
    userPromotionProfilesAccountLink,
    userSecurityAccountLink,
    userNotificationAccountLink,
    userVerificationAccountLink,
    userIdentityAccountLink,
    userProfilePromotionAccountLink,
    userPublicationPromotionAccountLink,
    userReferralAccountLink,
    userOfferAccountLink,
    publicationStatsLink,
    profileStatsLink,
    contentDescriptionLink,
    contentBidsLink,
    contentOwnersLink,
    contentCommentsLink,
    contentHistoryLink,
    confirmLoginLink,
    userIdentityFailedAccountLink,
    userIdentityPendingAccountLink,
    userIdentitySuccessAccountLink,
    userVerificationFailedAccountLink,
    userVerificationPendingAccountLink,
    userVerificationSuccessAccountLink,
    updateContentLink,
    createPublicationLink,
  };
};

export { useLink };
