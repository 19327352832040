<script setup>
import AppModal from "@/components/slots/modals/AppModal";
import AppModalImage from "@/components/slots/modals/AppModalImage";
import AppModalText from "@/components/slots/modals/AppModalText";
import AppModalMessage from "@/components/slots/modals/AppModalMessage";
import { useGeneralStore } from "@/stores/general";
import { useI18n } from "vue-i18n";
import AppBtn from "@/components/slots/buttons/AppBtn";
import { ErrorsEnum } from "@/utils/enum/general/errorsEnum";
import { useLink } from "@/composition/common/useLink";

const general = useGeneralStore();
const { t } = useI18n();
const { signInLink, signUpLink } = useLink();
</script>

<template>
  <app-modal class="success-modal error-modal" @close="general.setError(null)">
    <app-modal-image>
      <img src="@/assets/img/svg/error-img.svg" alt="" />
    </app-modal-image>
    <app-modal-text> {{ t("error-modal-phrase-1") }} </app-modal-text>
    <app-modal-message>
      {{ t(general.getError) }}
    </app-modal-message>
    <div v-if="general.getError === ErrorsEnum.NEED_LOGIN" class="w-100">
      <app-btn @click="general.setError(null)" :to="signInLink(true)">
        {{ t("sign-in-phrase-9") }}
      </app-btn>
      <app-btn
        @click="general.setError(null)"
        :to="signUpLink()"
        class="page-btn page-btn--light mt-1"
      >
        {{ t("sign-up-phrase-6") }}
      </app-btn>
    </div>
  </app-modal>
</template>
