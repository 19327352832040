import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

class ContentApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/content`,
    });
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async checkContentType(webId: number): Promise<[null, string] | [unknown]> {
    return this.axiosCall<string>({
      method: "get",
      url: `/type/${webId}`,
    });
  }
}

export const contentApi = new ContentApiService();
