export const useNumberFormatter = () => {
  const formatNumberByDefault = (val: number) => {
    const toFixed = (x: number): string => {
      if (Math.abs(x) < 1.0) {
        const e = parseInt(x.toString().split("e-")[1]);
        if (e) {
          x *= Math.pow(10, e - 1);
          return "0." + new Array(e).join("0") + x.toString().substring(2);
        }
      } else {
        let e = parseInt(x.toString().split("+")[1]);
        if (e > 20) {
          e -= 20;
          let val = "";
          x /= Math.pow(10, e);
          val = x.toString();
          val += new Array(e + 1).join("0");
          return val;
        }
      }
      return x.toString();
    };
    if (!val) {
      return 0;
    }
    if (val >= 1 || val <= -1) {
      return +val.toFixed(3).replace(/.$/, "");
    }
    if (val.toString().includes("e")) {
      return (+toFixed(val)).toFixed(9).replace(/.$/, "");
    }
    return +val.toFixed(9).replace(/.$/, "");
  };

  const numberToUsdFormat = (val: number) => {
    return val.toFixed(2);
  };

  const numberToShortcut = (val: number) => {
    let number: string | number = val;
    let decPlaces = 2;
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    const abbrev = ["K", "M", "B", "T"];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      const size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number = number.toString() + abbrev[i];

        // We are done... stop
        break;
      }
    }

    return number;
  };

  return {
    formatNumberByDefault,
    numberToUsdFormat,
    numberToShortcut,
  };
};
