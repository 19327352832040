import { RouteLocationNormalized } from "vue-router";
import { NextDefault } from "@/types/router/NextDefault";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { contentApi } from "@/api/guards/content.api";
import { useLink } from "@/composition/common/useLink";

export const ContentStackGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (nav?: NextDefault | string) => void
) => {
  const { contentDescriptionLink } = useLink();
  if (from.name) {
    next();
    return;
  }
  const webId: number = +to.params.id;
  const [error, response] = await contentApi.checkContentType(webId);
  if (error) {
    await useExceptionHandler(error);
    next();
  }
  if (response) {
    if (response === "PUBLICATION") {
      next();
    } else {
      const chunks = response.split(":");
      if (chunks[0] === "NFT" && !to.params.contractAddress) {
        next({
          path: contentDescriptionLink(webId, chunks[1]),
        });
        return;
      } else {
        next();
      }
    }
  }
  next();
};
