// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SockJs from "sockjs-client";
import Stomp, { Client, Message, Subscription } from "webstomp-client";
import { SubscribeToUserOnlineStatusRequest } from "@/types/socket/user/requests/SubscribeToUserOnlineStatusRequest";
import { ConnectToSocketRequest } from "@/types/socket/common/ConnectToSocketRequest";
import { SubscribeToReceiveAppNotifications } from "@/types/socket/user/requests/SubscribeToReceiveAppNotifications";

export class UserSocketService {
  private static instance: UserSocketService;
  static userSocket: Client;

  private constructor() {
    const connection = process.env.NODE_ENV === "development" ? "ws" : "wss";
    const socket = new SockJs(
      `${process.env.VUE_APP_API_URL}/${connection}`,
      null,
      {}
    );
    UserSocketService.userSocket = Stomp.over(socket, { debug: false });
  }

  public static getInstance(): UserSocketService {
    if (!UserSocketService.instance) {
      UserSocketService.instance = new UserSocketService();
    }

    return UserSocketService.instance;
  }

  public static connect(payload: ConnectToSocketRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userSocket.connect(
        { Authorization: payload.bearer },
        () => {
          resolve();
        },
        () => {
          reject();
        }
      );
    });
  }

  public static disconnect() {
    if (this.userSocket?.connected) {
      this.userSocket.disconnect();
    }
  }

  public static subscribeToUserOnlineStatus(
    payload: SubscribeToUserOnlineStatusRequest
  ): Subscription | null {
    if (!this.userSocket.connected) {
      return null;
    }
    return this.userSocket.subscribe(
      `/user_status/${payload.userId}`,
      (tick: Message) => {
        payload.cb(JSON.parse(tick.body));
      }
    );
  }

  public static unsubscribeFromUserOnlineStatus(payload: string) {
    this.userSocket.unsubscribe(payload);
  }

  public static subscribeToUserReceiveAppNotifications(
    payload: SubscribeToReceiveAppNotifications
  ): Subscription | null {
    if (!this.userSocket.connected) {
      return null;
    }
    return this.userSocket.subscribe(
      `/user/${payload.userId}/notifications/receive`,
      (tick: Message) => {
        payload.cb(JSON.parse(tick.body));
      }
    );
  }

  public static subscribeToUpdateBalances(
    payload: SubscribeToReceiveAppNotifications
  ): Subscription | null {
    if (!this.userSocket.connected) {
      return null;
    }
    return this.userSocket.subscribe(
      `/user/${payload.userId}/balances/receive`,
      (tick: Message) => {
        payload.cb(JSON.parse(tick.body));
      }
    );
  }
}
