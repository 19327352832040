import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { SendToVerificationRequest } from "@/types/requests/publicPersonRequestController/SendToVerificationRequest";
import { GetVerificationStatusResponse } from "@/types/responses/publicPersonRequestController/GetVerificationStatusResponse";

class PublicPersonRequestApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/public_person`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async sendToVerification(
    payload: SendToVerificationRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/send_to_verification",
      data: payload,
    });
  }

  async getVerificationRequest(): Promise<
    [null, GetVerificationStatusResponse] | [unknown]
  > {
    return this.axiosCall<GetVerificationStatusResponse>({
      method: "post",
      url: "/get_verification_request",
    });
  }
}

export const publicPersonRequestApi = new PublicPersonRequestApiService();
