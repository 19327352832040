import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { LanguageEnum } from "@/utils/enum/general/languageEnum";

const loadLocaleMessages = () => {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: { [key: string]: LocaleMessages<VueMessageType> } = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale: string = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
};

const i18n = createI18n({
  legacy: false,
  //globalInjection: true, //enable it to use global scope $t
  locale: localStorage.getItem("lang") || LanguageEnum.EN,
  fallbackLocale: localStorage.getItem("lang") || LanguageEnum.EN,
  messages: loadLocaleMessages(),
});

export { i18n };
