export const useTextUtils = () => {
  const capitalizeFirstLetter = (string: string) => {
    return (
      string.toLowerCase().charAt(0).toUpperCase() +
      string.toLowerCase().slice(1)
    );
  };

  const cutHash = (v: string) => {
    if (!v) {
      return v;
    }
    const valStart = v.substring(0, 8);
    const valEnd = v.substring(v.length - 8, v.length);
    return `${valStart}...${valEnd}`;
  };

  return {
    capitalizeFirstLetter,
    cutHash,
  };
};
