<script setup>
import SuccessModal from "@/components/modals/general/SuccessModal";
import { useGeneralStore } from "@/stores/general";
import ErrorModal from "@/components/modals/general/ErrorModal";
import EmailInfoModal from "@/components/modals/auth/EmailInfoModal";
import SendTransactionModal from "@/components/modals/blockchain/SendTransactionModal";
import { useTransactionStore } from "@/stores/transaction";
import JoinBusinessModal from "@/components/modals/business/JoinBusinessModal";
import { useBusinessStore } from "@/stores/business";
import ProvideDataModal from "@/components/modals/profile/ProvideDataModal";
import { useToggleVisible } from "@/composition/common/useToggleVisible";
import { watch } from "vue";
import { useEventsBus } from "@/composition/event/useEventBus";
import { EventsEnum } from "@/utils/enum/general/eventsEnum";
import ConfirmEmailModal from "@/components/modals/auth/ConfirmEmailModal";
import { useUserStore } from "@/stores/user";

const general = useGeneralStore();
const transaction = useTransactionStore();
const business = useBusinessStore();
const user = useUserStore();
const { bus } = useEventsBus();

const {
  visible: provideDataModalVisible,
  close: hideProvideDataModalVisible,
  open: openProvideDataModalVisible,
} = useToggleVisible();

const {
  visible: confirmEmailModalVisible,
  close: hideConfirmEmailModalVisible,
  open: openConfirmEmailModalVisible,
} = useToggleVisible();

watch(
  () => bus.value.get(EventsEnum.NEED_PROVIDE_PERSONAL_INFO),
  () => {
    openProvideDataModalVisible();
  }
);
watch(
  () => user.user?.id,
  (value) => {
    if (value && !user.getRegistrationComplete) {
      openConfirmEmailModalVisible();
    }
  }
);
</script>
<template>
  <transition-group name="fade">
    <email-info-modal v-if="general.getEmailInfo" />
    <error-modal v-if="general.getError" />
    <success-modal v-if="general.getSuccess" />
    <send-transaction-modal
      v-if="!transaction.ignoreStateMutation && transaction.getTxStatus"
    />
    <join-business-modal
      v-if="business.connectBusinessModalVisible"
      @close="business.setConnectBusinessModalVisible(false)"
    />

    <provide-data-modal
      v-if="provideDataModalVisible"
      @close="hideProvideDataModalVisible"
    />

    <confirm-email-modal
      v-if="confirmEmailModalVisible"
      @close="hideConfirmEmailModalVisible"
    />
  </transition-group>
</template>
