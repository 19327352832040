import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { subscriptionAPI } from "@/api/subscription.api";
import { CurrentSubscription } from "@/types/subscriptions/CurrentSubscription";
import { DurationsEnum } from "@/utils/enum/subscription/durationsEnum";
import { useReferralStore } from "@/stores/referral";

export const useSubscriptionStore = defineStore({
  id: "subscription",
  state: () => ({
    subscription: null as CurrentSubscription | null,
  }),
  getters: {
    getContentPerDayCount(): number {
      return this.subscription?.contentPerDayCount || 0;
    },
    getDuration(): DurationsEnum | null {
      return this.subscription?.duration || null;
    },
    getEndingDate(): number {
      return this.subscription?.endingDate || 0;
    },
    getFreeNftCount(): number {
      return this.subscription?.freeNftCount || 0;
    },
    getNftTotalCount(): number {
      return this.subscription?.nftTotalCount || 0;
    },
    isPaidSubscription(): boolean {
      return !!this.subscription?.endingDate;
    },
  },
  actions: {
    async getSubscription() {
      const [error, response] = await subscriptionAPI.getUserSubscriptions();
      if (error) {
        await useExceptionHandler(error);
      }

      if (response) {
        this.subscription = response;
        const referral = useReferralStore();
        referral.setIsPaidSubscription(this.isPaidSubscription);
      }
    },
  },
});
