<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 9.02333V2H13.3333V4H9.33334V11.3333C9.33321 11.9203 9.13939 12.4909 8.78196 12.9565C8.42453 13.4221 7.92344 13.7568 7.35642 13.9087C6.7894 14.0605 6.18812 14.021 5.64584 13.7963C5.10355 13.5716 4.65056 13.1742 4.35712 12.6658C4.06368 12.1574 3.94618 11.5664 4.02285 10.9844C4.09951 10.4025 4.36606 9.86206 4.78116 9.44701C5.19625 9.03196 5.7367 8.76547 6.31867 8.68887C6.90065 8.61227 7.49165 8.72983 8 9.02333V9.02333Z"
      fill="#ACADB2"
    />
  </svg>
</template>
