import { reactive, ref } from "vue";

export const useLazyLoading = () => {
  const loading = ref(false);
  const loaded = ref(false);
  const error = ref(false);

  const setFileUrl = (val: string) => {
    lazyOptions.src = val;
  };

  const lazyOptions = reactive({
    src: "",
    lifecycle: {
      loading: () => {
        loading.value = true;
        loaded.value = false;
        error.value = false;
      },
      error: () => {
        loading.value = false;
        loaded.value = false;
        error.value = true;
      },
      loaded: () => {
        loading.value = false;
        loaded.value = true;
        error.value = false;
      },
    },
  });

  return { lazyOptions, setFileUrl, loaded, loading, error };
};
