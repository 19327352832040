<template>
  <svg
    width="50"
    height="40"
    viewBox="0 0 50 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0039127 3.71698C0.0339113 3.62795 0.071736 3.54019 0.0939088 3.44989C0.590842 1.33735 2.2812 0.00699517 4.51022 0.00445147C9.05001 -0.00148382 13.5902 -0.00148382 18.1309 0.00445147H30.6781C33.5032 0.00445147 35.3488 1.80921 35.3514 4.56785C35.3514 5.88294 35.3514 7.19803 35.3514 8.57417H34.7566C29.5491 8.57417 24.341 8.57417 19.1326 8.57417C15.5419 8.57417 12.5968 10.9042 11.8703 14.3166C11.7701 14.8305 11.7224 15.3528 11.7282 15.8759C11.7125 18.1766 11.7216 20.4787 11.7216 22.857C11.2456 22.857 10.7812 22.857 10.3182 22.857C9.5226 22.857 8.6409 22.6713 7.95615 22.9448C7.2714 23.2182 6.81751 23.9534 6.26058 24.4901C5.10759 25.611 3.95634 26.7332 2.80682 27.8567C1.70601 28.9301 0.791702 28.8207 0 27.5222L0.0039127 3.71698Z"
      fill="white"
    />
    <path
      d="M45.3046 11.4308C48.1583 11.4308 50 13.2304 50 16.0196C50 23.4455 50 30.8718 50 38.2986C50 38.7768 49.9296 39.2143 49.5722 39.5704C48.9735 40.1669 48.0801 40.144 47.4045 39.4903C45.8224 37.9641 44.2337 36.4379 42.6843 34.8709C42.4991 34.67 42.2702 34.512 42.0142 34.4086C41.7583 34.3051 41.4818 34.2589 41.2052 34.2732C33.9325 34.2935 26.6598 34.2978 19.3871 34.2859C16.4733 34.2859 14.6486 32.4977 14.6486 29.6437C14.6486 25.1057 14.6486 20.5694 14.6486 16.0349C14.6486 13.2254 16.4916 11.4371 19.3832 11.4371L45.3046 11.4308ZM32.3778 18.5735C29.451 18.5735 26.5241 18.5735 23.5973 18.5735C22.9126 18.5735 22.3895 18.8278 22.1026 19.4371C21.6474 20.4126 22.3948 21.43 23.566 21.4313C26.672 21.4381 29.7775 21.4381 32.8825 21.4313C35.6137 21.4313 38.3449 21.4313 41.0761 21.4313C41.7399 21.4313 42.2499 21.177 42.5356 20.5894C43.0038 19.6151 42.2747 18.5786 41.1087 18.5747C38.1988 18.5684 35.2876 18.5735 32.3778 18.5735ZM32.3269 27.1445C34.2773 27.1445 36.2276 27.1445 38.1779 27.1445C38.8444 27.1445 39.3518 26.8723 39.6127 26.2771C40.0626 25.3003 39.3153 24.2904 38.144 24.2892C34.259 24.2841 30.3744 24.2841 26.4902 24.2892C25.5537 24.2892 24.8912 24.8984 24.899 25.7302C24.9068 26.562 25.5681 27.1419 26.5176 27.1445C28.4567 27.1479 30.3931 27.1479 32.3269 27.1445Z"
      fill="white"
    />
  </svg>
</template>
