import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { AddRatingMarksRequest } from "@/types/requests/ratingMarksController/AddRatingMarksRequest";
import { CancelRatingMarksRequest } from "@/types/requests/ratingMarksController/CancelRatingMarksRequest";
import { GetRatingMarksResponse } from "@/types/responses/ratingMarksController/GetRatingMarksResponse";
import { GetTransactionsResponse } from "@/types/responses/ratingMarksController/GetTransactionsResponse";
import { AddRatingMarksResponse } from "@/types/responses/ratingMarksController/AddRatingMarksResponse";
import { DefaultPageableRequest } from "@/types/abstract/pagination/request/DefaultPageableRequest";

class RatingMarksApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/rating_marks`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async addRatingMarks(
    payload: AddRatingMarksRequest
  ): Promise<[null, AddRatingMarksResponse] | [unknown]> {
    return this.axiosCall<AddRatingMarksResponse>({
      method: "post",
      url: "/add",
      params: payload,
    });
  }

  async cancelRatingMarks(
    payload: CancelRatingMarksRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "/cancel",
      params: payload,
    });
  }

  async getRatingMarks(): Promise<[null, GetRatingMarksResponse] | [unknown]> {
    return this.axiosCall<GetRatingMarksResponse>({
      method: "get",
      url: "/",
    });
  }

  async getTransactions(
    payload: DefaultPageableRequest
  ): Promise<[null, GetTransactionsResponse] | [unknown]> {
    return this.axiosCall<GetTransactionsResponse>({
      method: "get",
      url: "/transactions",
      params: payload.params,
      data: payload.data,
    });
  }
}

export const ratingMarksApi = new RatingMarksApiService();
