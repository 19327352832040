import { defineStore } from "pinia";
import { BusinessStatusEnum } from "@/utils/enum/business/businessStatusEnum";
import { yollloBusinessApi } from "@/api/yollloBusiness.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { useReferralStore } from "@/stores/referral";

export const useBusinessStore = defineStore({
  id: "business",
  state: () => ({
    businessStatus: null as null | BusinessStatusEnum,
    connectBusinessModalVisible: false,
    name: "",
    dateActiveFrom: [] as number[],
    dateActiveTo: [] as number[],
  }),
  getters: {
    isBusinessConnected(): boolean {
      return this.businessStatus === BusinessStatusEnum.CONNECTED;
    },
    getName(): string {
      return this.name;
    },
    getDateActiveFrom(): number {
      return new Date(...(this.dateActiveFrom as [])).getTime();
    },
    getDateActiveTo(): number {
      return new Date(...(this.dateActiveTo as [])).getTime();
    },
  },
  actions: {
    setConnectBusinessModalVisible(payload: boolean) {
      this.connectBusinessModalVisible = payload;
    },
    setBusinessStatus(payload: BusinessStatusEnum) {
      this.businessStatus = payload;
    },

    async getSubscribeInfo() {
      const [error, response] = await yollloBusinessApi.subscribe();
      if (error) {
        await useExceptionHandler(error);
        return;
      }
      if (response) {
        this.name = response.name;
        this.dateActiveFrom = response.dateActiveFrom;
        this.dateActiveTo = response.dateActiveTo;
        const referral = useReferralStore();
        referral.setIsBusinessConnected(this.isBusinessConnected);
      }
    },
  },
});
