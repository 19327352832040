import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  appRequestErrorInterceptor,
  appRequestSuccessInterceptor,
} from "@/utils/axios/interceptors";
import { GetAllSubscriptionsResponse } from "@/types/responses/subscriptionController/GetAllSubscriptionsResponse";
import { SubscriptionResponse } from "@/types/responses/subscriptionController/SubscriptionResponse";
import { BuySubscriptionRequest } from "@/types/responses/subscriptionController/BuySubscriptionRequest";
import { GetTermsResponse } from "@/types/responses/subscriptionController/GetTermsResponse";

class SubscriptionApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/subscriptions`,
    });
    this.axiosInstance.interceptors.request.use(
      appRequestSuccessInterceptor,
      appRequestErrorInterceptor
    );
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[null, T] | [unknown]> {
    try {
      const { data } = await this.axiosInstance.request<T>(config);
      return [null, data];
    } catch (e) {
      return [e];
    }
  }

  async getUserSubscriptions(): Promise<
    [null, SubscriptionResponse] | [unknown]
  > {
    return this.axiosCall<SubscriptionResponse>({
      method: "get",
      url: "",
    });
  }

  async getAvailableSubscriptions(): Promise<
    [null, GetAllSubscriptionsResponse] | [unknown]
  > {
    return this.axiosCall<GetAllSubscriptionsResponse>({
      method: "get",
      url: "/available",
    });
  }

  async buySubscription(
    payload: BuySubscriptionRequest
  ): Promise<[null, void] | [unknown]> {
    return this.axiosCall<void>({
      method: "post",
      url: "",
      params: payload,
    });
  }

  async getTerms(): Promise<[null, GetTermsResponse] | [unknown]> {
    return this.axiosCall<GetTermsResponse>({
      method: "get",
      url: "/terms",
    });
  }
}

export const subscriptionAPI = new SubscriptionApiService();
