export enum AppNotificationsTypeEnum {
  NEW_LIKE_ON_YOUR_POST = "NEW_LIKE_ON_YOUR_POST",
  NEW_YRM_ON_YOUR_POST = "NEW_YRM_ON_YOUR_POST",
  DEPOSIT_SUCCESSFUL = "DEPOSIT_SUCCESSFUL",
  WITHDRAWAL_REQUEST_SUCCESS = "WITHDRAWAL_REQUEST_SUCCESS",
  WITHDRAWAL_CONFIRMATION = "WITHDRAWAL_CONFIRMATION",
  NEW_SUBSCRIBER = "NEW_SUBSCRIBER",
  CHANGE_PASSWORD_SUCCESSFUL = "CHANGE_PASSWORD_SUCCESSFUL",
  REPORT_ON_YOUR_POST = "REPORT_ON_YOUR_POST",
  POST_GET_10_YRM_MINTING_NFT_AVAILABLE = "POST_GET_10_YRM_MINTING_NFT_AVAILABLE",
  CALIBRATED_THE_FEED_SUCCESSFUL = "CALIBRATED_THE_FEED_SUCCESSFUL",
  NEW_BID_ON_YOUR_AUCTION = "NEW_BID_ON_YOUR_AUCTION",
  YOUR_AUCTION_CLOSED = "YOUR_AUCTION_CLOSED",
  SELL_NFT_SUCCESSFUL = "SELL_NFT_SUCCESSFUL",
  TRANSFER_NFT_SUCCESSFUL = "TRANSFER_NFT_SUCCESSFUL",
  NFT_MINTED_SUCCESSFUL = "NFT_MINTED_SUCCESSFUL",
  YOUR_BID_WIN_ON_AUCTION = "YOUR_BID_WIN_ON_AUCTION",
  NFT_AUCTION_TIME_OUT = "NFT_AUCTION_TIME_OUT",
}
