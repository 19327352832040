import { defineStore } from "pinia";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";
import { utilApi } from "@/api/util.api";
import { GetUsdPricesResponse } from "@/types/responses/utilController/GetUsdPricesResponse";
import { CurrencyEnum } from "@/utils/enum/wallet/currencyEnum";

export const useUtilStore = defineStore({
  id: "util",
  state: () => ({
    usdPrices: [] as GetUsdPricesResponse[],
  }),
  getters: {},
  actions: {
    calcCurrencyUsdPrice(priceValue: number, currency: CurrencyEnum) {
      return (
        priceValue *
        (this.usdPrices.find((i) => i.currency === currency)?.price || 0)
      );
    },
    async getUsdPrices() {
      const [error, response] = await utilApi.getUsdPrices();
      if (error) {
        await useExceptionHandler(error);
      }

      if (response) {
        this.usdPrices = response;
      }
    },
  },
});
