import { defineStore } from "pinia";
import { IdentityStatusesEnum } from "@/utils/enum/identity/identityStatusesEnum";
import { publicPersonRequestApi } from "@/api/publicPersonRequest.api";
import { useExceptionHandler } from "@/composition/general/useExceptionHandler";

export const useIdentityStore = defineStore({
  id: "identity",
  state: () => ({
    status: null as IdentityStatusesEnum | null,
  }),
  getters: {
    isIdentified(): boolean {
      return this.status === IdentityStatusesEnum.APPROVE;
    },
    isPending(): boolean {
      return this.status === IdentityStatusesEnum.PENDING;
    },
    isCanceled(): boolean {
      return this.status === IdentityStatusesEnum.REJECTED;
    },
  },
  actions: {
    setIdentityStatus(payload: IdentityStatusesEnum) {
      this.status = payload;
    },

    async getVerificationRequest() {
      const [error, response] =
        await publicPersonRequestApi.getVerificationRequest();

      if (error) {
        await useExceptionHandler(error);
        return;
      }
      if (response) {
        this.status = response.status;
      }
    },
  },
});
